import React, { useState } from "react";
import TopbarLayout from "../Topbar/TopbarLayout";
import SidebarSig from "./SidebarSig";
import RightSidebar from "../RightSidebar";
import FaqComp from "../FAQs/FaqComp";

const SigFaq = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 sidebar">
          <SidebarSig />
        </div>
        <div className="col-7 middle-content">
          <div className="container-fluid border-bottom sticky-top bg-white mb-4">
            <nav className="row header bg-white  ">
              <TopbarLayout />
            </nav>
          </div>

          <FaqComp />
          <div className="footer">
            <div>© 2023 MYOT</div>
            <div className="d-flex ">
              <p className="ms-3">About</p>
              <p className="ms-3">Support</p>
              <p className="ms-3">Contact Us</p>
            </div>
          </div>
        </div>

        <div className="col">
          <RightSidebar />
        </div>
      </div>
    </div>
  );
};

export default SigFaq;
