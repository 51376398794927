import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NotificatioModal from "../RightSidebarCommon/NotificatioModal";
import Notifications from "../DepartmentManager/Notifications";
const TopSidebar = () => {
  const [modal, setModal] = useState(false);
  const location = useLocation();
  const notificationModal = useRef(null);

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (
  //       !notificationModal.current ||
  //       !notificationModal.current.contains(e.target)
  //     ) {
  //       setModal(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // }, []);

  const closeModal = () => {
    setModal(false);
  };
  return (
    <>
      <div className="col d-flex align-items-center justify-content-end">
        {/* <form className="" role="search">
          <input
            className="form-control search-bar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form> */}
        <div className="">
          {/* <img
            src="/images/dashboard/announcement.png"
            alt=""
            className="ms-4 "
          /> */}
          {location.pathname.startsWith("/Admin") ? (
            <Link to={"/Admin/Chat"}>
              <img
                src="/images/dashboard/chat-left-dots-fill.png"
                alt=""
                className="ms-4"
              />
            </Link>
          ) : location.pathname.startsWith("/Department") ? (
            <Link to={"/Department/Chat"}>
              <img
                src="/images/dashboard/chat-left-dots-fill.png"
                alt=""
                className="ms-4"
              />
            </Link>
          ) : null}
          {/* <div className="position-relative"> */}
          {location.pathname.startsWith("/Admin") ? (
            <>
          <img
            src="/images/dashboard/round-notifications.png"
            alt=""
            className="ms-4 cursor_pointer position-relative"
            onClick={() => setModal(!modal)}
            ref={notificationModal}
          />

          {modal && (
            <div className="position-absolute top-0 end-0 mt-5">
              <NotificatioModal closeModal={closeModal}  />
            </div>
          )}
          </>
        ): location.pathname.startsWith("/Department") ? (
          <>
          <img
            src="/images/dashboard/round-notifications.png"
            alt=""
            className="ms-4 cursor_pointer position-relative"
            onClick={() => setModal(!modal)}
            ref={notificationModal}
          />

          {modal && (
            <div className="position-absolute top-0 end-0 mt-5">
              <Notifications closeModal={closeModal} />
            </div>
          )}
          </>
        ):""}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default TopSidebar;
