import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import NotificatioModal from "../RightSidebarCommon/NotificatioModal";

const SigTopSidebar = () => {
  const [modal, setModal] = useState(false);

  const notificationModal = useRef(null);

  // useEffect(() => {
  //   let handler = (e) => {
  //     if (
  //       !notificationModal.current ||
  //       !notificationModal.current.contains(e.target)
  //     ) {
  //       setModal(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // }, []);

  const closeModal = () => {
    setModal(false);
  };
  return (
    <>
      <div className="col d-flex align-items-center  justify-content-end">
        {/* <form className="" role="search">
          <input
            className="form-control search-bar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </form> */}
        <div className="">
          {/* <img
            src="/images/dashboard/announcement.png"
            alt=""
            className="ms-4 "
          /> */}
          <Link to={"/Signatory/Chat"}>
            <img
              src="/images/dashboard/chat-left-dots-fill.png"
              alt=""
              className="ms-4"
            />
          </Link>
          <img
            src="/images/dashboard/round-notifications.png"
            alt=""
            className="ms-4 cursor_pointer position-relative"
            onClick={() => setModal(!modal)}
            ref={notificationModal}
          />
          {modal && (
            <div className="position-absolute top-0 end-0 mt-5">
              <NotificatioModal closeModal={closeModal} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SigTopSidebar;
