import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../app/slice/userSlice";

const Switcher = () => {
  const [role, setRole] = useState();
  const [allRoleOfUser, setAllRoleOfUser] = useState([]);
  const userData = useSelector(selectUserData);

  const location = useLocation();

  useEffect(() => {
    const pathLocation = location.pathname.split("/");
    setRole(pathLocation[1]);
  }, [location]);

  useEffect(() => {
    const getRole = userData?.employRole.flat(Infinity);
    setAllRoleOfUser(getRole);
  }, []);

  const rolesToShow = [
    "Admin",
    "Department Manager",
    "Signatory",
    "Employee",
    "Approver",
  ];

  return (
    <>
      <a
        className="text-decoration-none pb-2 bg-white w-100"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className="profile">
          <div>
            {userData?.profile_Pic ? (
              <img
                className="profile_icon"
                src={userData?.profile_Pic}
                alt="Profile"
              />
            ) : (
              <img
                className="profile_icon"
                src="/images/icons/user.jpg"
                alt="Profile"
              />
            )}
          </div>
          <div>
            <span className="text-dark text-capitalize">
              {userData?.name?.split(" ")?.slice(0, 2)?.join(" ") ||
                userData?.companyName}
            </span>
            <p className="th-text">
              {role && role === "Department" ? "Department Manager" : role}
            </p>
          </div>
          <div>
            <img src="/images/sidebar/ArrowLineDown2.svg" alt="hh" />
          </div>
        </div>
      </a>
      <ul className="dropdown-menu">
        {rolesToShow?.map((roleToShow) => {
          if (allRoleOfUser?.includes(roleToShow)) {
            const adjustedRole =
              roleToShow === "Department Manager" ? "Department" : roleToShow;
            return (
              <Link
                key={roleToShow}
                to={`/${adjustedRole}/Home`}
                className="text-decoration-none"
              >
                <li>
                  <a className="dropdown-item">{roleToShow}</a>
                </li>
              </Link>
            );
          }
        })}
      </ul>
    </>
  );
};

export default Switcher;
