import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import TopSidebar from "./TopSidebar";
import SigTopSidebar from "./SigTopSidebar";
import PrevNextLocation from "./PrevNextLocation";
import { useLocation } from "react-router-dom";
import TopBarEmply from "./TopBarEmply";
import TopbarAprv from "./TopbarAprv";

const TopbarLayout = () => {
  const location = useLocation();
  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-2">
        <Breadcrumbs />
        {location.pathname.startsWith("/Admin") ? (
          <TopSidebar />
        ) : location.pathname.startsWith("/Signatory") ? (
          <SigTopSidebar />
        ) : null}
      </div>
      <PrevNextLocation />
    </>
  );
};

export default TopbarLayout;
