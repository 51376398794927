import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const KeyBenefits = () => {
  const slides = [
    {
      imgSrc: "assets/img/benefits_2.png",
      title: "Save Time and Money by Going Paperless",
      description:
        "Cut costs on paper, ink, and printer maintenance. MYOT eliminates the need for printing, scanning, and troubleshooting, freeing up time for more important tasks.",
    },
    {
      imgSrc: "assets/img/benefits_3.png",
      title: "Reduce Your Environmental Footprint",
      description:
        "Join the movement towards sustainability. By going paperless with MYOT, your business can significantly reduce its environmental impact.",
    },
    {
      imgSrc: "assets/img/benefits_4.png",
      title: "All Your Document Needs, Digitized",
      description:
        "Create, manage, and sign documents—all within one platform. MYOT ensures a smooth, paperless workflow from start to finish.",
    },
    {
      imgSrc: "assets/img/benefits_01.png",
      title: "Enhanced Security",
      description:
        "Digital documents can be encrypted, securely stored, and accessed only by authorized users. This is far more secure than physical documents, which can be lost, stolen, or damaged.",
    },
  ];


  const settings = {
    loop: true,
    nav: false,
    dots: false,
    center: true,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 2,
        margin: -30,
      },
      992: {
        items: 3,
        margin: -40,
      },
    },
  };

  return (
    <section className="whychose_myot">
      <div class="heading_comman text-center">
        <h2>Key Benefits</h2>
      </div>
      <OwlCarousel className="owl-carousel whychose_slider" {...settings}>
        {slides.map((slide, index) => (
          <div className="whychose_box" key={index}>
            <img src={slide.imgSrc} alt="" />
            <div className="whychose_hover">
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
              <a className="comman_btn" href="javascript:;">
                Get Started <img src="assets/img/arrow-right.svg" alt="" />
              </a>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </section>
  );
};

export default KeyBenefits;
