import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groupList: [],
};

const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setCentralGroupList(state, action) {
      state.groupList = action.payload;
    },
  },
});

export default chatSlice.reducer;
export const { setCentralGroupList } = chatSlice.actions;
