import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {
  NotificationEmply,
  documentViewDetails,
} from "../../ApiServices/EmployeeHttpService/employeeLoginHttpService";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import moment from "moment";
import SidebarAprv from "./SidebarAprv";
import PrevNextLocation from "../Topbar/PrevNextLocation";
import NotificatioModal from "../RightSidebarCommon/NotificatioModal";
import NotificationModelEmply from "../Employee/NotificationModelEmply";
import Swal from "sweetalert2";
import {
  approvedDocumentRequest,
  approvedTemplete,
  rejectedDocumentRequest,
  rejectedTemplete,
} from "../../ApiServices/aprroverHttpServices/aprproverHttpService";

const DocumentViewAprv = () => {
  const [viewPage, setViewPage] = useState();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const { id } = useParams();
  const {state} = useLocation()
  const [reasons, setReason] = useState();
  const [modal, setModal] = useState(false);
  const [document_Id, setDocument_Id] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const viewDetail = async () => {
      const documentView = await documentViewDetails(state);
      const view = documentView;
      setViewPage(view);
    };
    viewDetail();
  }, []);
  console.log(viewPage);

  const closeModal = () => {
    setModal(false);
  };

  const approved = async () => {
    const approveData = await approvedDocumentRequest(state);
    if (!approveData?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "Document Approved",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      navigate(-1);
    }
    // templeteView();
  };

  const submitReason = async (e) => {
    e.preventDefault();
    const rejectedData = await rejectedDocumentRequest(state, {
      reasons,
    });
    if (rejectedData) {
      navigate(-1);
      setReason("");
      // templeteView();
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 sidebar">
          <SidebarAprv />
        </div>
        <div className="col-10 middle-content">
          <div className="container-fluid border-bottom mb-4">
            <nav className="row header bg-white  ">
              <ul className="col align-items-center mt-3">
                <li className="nav-item dropdown-hover d-none d-lg-block">
                  <a className="nav-link fw-bold ms-2">
                    Documents Requests Management / View
                  </a>
                </li>
              </ul>
              <div className="col d-flex align-items-center  justify-content-end">
                <form role="search">
                  <input
                    class="form-control search-bar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </form>
                <div className="">
                  {/* <img
                    src="/images/dashboard/announcement.png"
                    alt=""
                    className="ms-4 "
                  /> */}
                  <Link to={"/Approver/Chat"}>
                    <img
                      src="/images/dashboard/chat-left-dots-fill.png"
                      alt=""
                      className="ms-4"
                    />
                  </Link>
                  <img
                    src="/images/dashboard/round-notifications.png"
                    alt=""
                    className="ms-4 cursor_pointer position-relative"
                    onClick={() => setModal(!modal)}
                  />

                  {modal && (
                    <div className="position-absolute top-0 end-0 mt-5">
                      <NotificationModelEmply closeModal={closeModal} />
                    </div>
                  )}
                </div>
              </div>
            </nav>
            <PrevNextLocation />
          </div>
          <div className="container px-4 text-center min-vh-100 ">
            <div className="row bg-white rounded  p-4 m-4">
              <div className="row">
                <div className="d-flex justify-content-between">
                  <div className="col-6">
                    <p className="fs-6 text-capitalize mb-3 text-start">
                      Template Name:{" "}
                      {viewPage?.[0]?.[0]?.templete?.templeteName}
                    </p>
                    <p className="fs-6 text-capitalize mb-3 text-start">
                      Template Id: {viewPage?.[0]?.[0]?.templete?.templeteId}
                    </p>
                    {viewPage?.[0]?.[0]?.status === "Rejected" ? (
                      <p className="fs-6 text-start">
                        Reason:{" "}
                        <span className="text-danger">
                          {viewPage?.[0]?.[0]?.rejectReasons || "NA"}
                        </span>
                      </p>
                    ) : null}
                  </div>
                  <div className=" col-6">
                    <p className="fs-6 text-end">
                      Status:
                      <span
                        className={`"td-text status" ${
                          viewPage?.[0]?.[0]?.status === "Pending"
                            ? "text-info"
                            : viewPage?.[0]?.[0]?.status === "Approved"
                            ? "text-warning"
                            : viewPage?.[0]?.[0]?.status === "In Progress"
                            ? "text-primary"
                            : viewPage?.[0]?.[0]?.status === "Completed"
                            ? "text-success"
                            : viewPage?.[0]?.[0]?.status ===
                              "Partially Approved"
                            ? "text-info"
                            : "text-danger"
                        }`}
                      >
                        {viewPage?.[0]?.[0]?.status}
                      </span>
                    </p>
                    {viewPage && (
                      <div className="fs-6">
                        <p className="fs-6 text-end text-capitalize mt-3">
                          Date:{" "}
                          {moment(viewPage?.[0]?.[0]?.createdAt).format(
                            "MM-DD-YYYY"
                          )}
                        </p>
                      </div>
                    )}
                    <div className="d-flex justify-content-end">
                      <div className="me-2">
                        {viewPage?.[0]?.[0]?.status !== "Completed" &&
                        viewPage?.[0]?.[0]?.status !== "Approved" ? (
                          <button
                            className="py-2 mt-3 px-3 bg-success text-white rounded-2"
                            onClick={() => approved()}
                            disabled={viewPage?.[0]?.[0]?.status === "Approved"}
                          >
                            Approve
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mt-3">
                        {viewPage?.[0]?.[0]?.status !== "Completed" &&
                        viewPage?.[0]?.[0]?.status !== "Approved" &&
                        viewPage?.[0]?.[0]?.status !== "Rejected" ? (
                          <button
                            // className="py-2 px-4 bg-danger text-white rounded-2"
                            // onClick={() => setDocument_Id(id)}
                            data-bs-toggle="modal"
                            data-bs-target="#reason"
                            className=" py-2 px-4 bg-danger text-white rounded-2"
                            disabled={viewPage?.[0]?.[0]?.status === "Rejected"}
                            href="#"
                          >
                            Reject
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-4">
          <p>Assigned To : {tempData?.templeteName}</p>
        </div>
        <div className="col-md-4">
          <p>Created By : {tempData?.templeteName}</p>
        </div> */}

                <div className="mt-5 position-relative d-flex col-12">
                  <div className="col-10">
                    {viewPage && (
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js`}
                      >
                        <div>
                          <Viewer
                            // fileUrl={tempData?.templete[0]}
                            fileUrl={`${viewPage?.[0]?.[0]?.templete?.templete?.[0]}`}
                            // fileUrl="http://ec2-51-112-25-93.me-central-1.compute.amazonaws.com:2053/17059962825372ocnt.pdf"
                            plugins={[defaultLayoutPluginInstance]}
                          />
                        </div>
                      </Worker>
                    )}
                  </div>
                  <div className="col-2 ms-2 mt-3 text-end">
                    <div className="">
                      <p
                        className="text-center text-white fw-3  px-3 py-1 rounded-1"
                        style={{ background: "gray" }}
                      >
                        Required Documents
                      </p>
                      {viewPage?.[0]?.[0]?.templete &&
                      viewPage?.[0]?.[0]?.templete?.fileName?.length > 0 ? (
                        viewPage?.[0]?.[0]?.templete?.fileName?.map(
                          (item, index) => (
                            <span className="fw-3 fs-6" key={index}>
                              <p className="text-center">
                                {" "}
                                {item.charAt(0).toUpperCase() + item.slice(1)}
                              </p>
                            </span>
                          )
                        )
                      ) : (
                        <p className="text-center mt-2">NA</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <p
                        className="text-center fw-3  px-3 py-1 text-white rounded-1"
                        style={{ background: "gray" }}
                      >
                        Department
                      </p>
                      {viewPage?.[0]?.[0]?.templete &&
                      viewPage?.[0]?.[0]?.templete?.department?.length > 0 ? (
                        viewPage?.[0]?.[0]?.templete?.department?.map(
                          (item, index) => (
                            <span className="fw-3 fs-6" key={index}>
                              <p className="text-center">
                                {" "}
                                {item?.departmentName}
                              </p>
                            </span>
                          )
                        )
                      ) : (
                        <p className="text-center mt-2">NA</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="reason"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title th-text fs-6" id="exampleModalLabel">
                Add Reason
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeForm"
              ></button>
            </div>
            <div class="modal-body">
              <form className="rounded" onSubmit={submitReason}>
                <div className="mb-3">
                  <label className="form-label th-text"></label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Type reason..."
                    value={reasons}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>

                <div className="d-flex justify-content-end">
                  {/* <div>
                        <img
                          src="/images/tasks/assign comments.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/mention.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/task.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/emoji.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/attach_attachment.svg"
                          alt=""
                          className="comment-img"
                        />
                      </div> */}
                  <div>
                    <button
                      style={{ width: "100px" }}
                      type="submit"
                      color="red"
                      className="bg-danger py-2 px-3 rounded-4 text-white"
                      // className="comment-btn"
                      data-bs-dismiss="modal"
                      // disabled={!reasons || /^\s+$/.test(reasons)}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentViewAprv;
