import React from "react";
import { useNavigate } from "react-router-dom";

const PrevNextLocation = () => {
  const navigate = useNavigate();
  return (
    <div className="position-relative top-0 mb-2">
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="bg-white cursor_pointer"
          onClick={() => navigate(-1)}
        >
          <img
            src="/images/icons/left.svg"
            className="bg-white w_20_h_20"
            alt=""
          />
        </button>
        <button className="bg-white cursor_pointer" onClick={() => navigate(1)}>
          <img
            src="/images/icons/right.svg"
            className="bg-white w_20_h_20"
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

export default PrevNextLocation;
