import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const draftSlice = createSlice({
  name: "draft",
  initialState: {
    draft: "",
  },
  reducers: {
    getDraftData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { getDraftData } = draftSlice.actions;

export default draftSlice.reducer;
