import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const SideCalendar = () => {
  const tasks = useSelector((state) => state.task);
  const today = moment().startOf("day");
  const nextWeek = moment().add(7, "days").endOf("day");

  const upcomingTasks = tasks?.tasksList?.filter((task) => {
    const taskStartDate = moment.utc(task?.date);
    const taskEndDate = moment.utc(task?.endDate);

    return (
      taskStartDate.isBetween(today, nextWeek, null, "[]") ||
      taskEndDate.isBetween(today, nextWeek, null, "[]")
    );
  });

  return (
    <>
      <div className="row bg-white">
        <div className="col float-start mt-4">
          <h4 className="text-align-left float-start current-day">Calendar</h4>
        </div>
        {/* <div className="col float-end">
          <div className=" float-end me-1 mt-4">
            <img src="/images/chatbox/More-dots.svg" alt="" className="me-2" />
          </div>
        </div> */}
      </div>
      <div>
        <div>
          {upcomingTasks?.length > 0 ? (
            upcomingTasks?.map((task, index) => (
              <div className="row bg-white align-items-center" key={task?._id}>
                <div className="col-3">
                  <p className="day-time">
                    {moment.utc(task?.date).format("DD/MM")}
                    <br />
                    {moment.utc(task?.date).format("hh:mm A")}
                  </p>
                </div>
                <div className="col-6 border-bottom d-flex">
                  <div className="event-9am right-cal-event">
                    <p className="w-100 text-truncate">{task?.taskName}</p>
                  </div>
                </div>
                <div className="col-3">
                  <p className="day-time">
                    {moment.utc(task?.endDate).format("DD/MM")}
                    <br />
                    {moment.utc(task?.endDate).format("hh:mm A")}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="row bg-light">
              <div className="text-center my-2 rounded-1">
                <p className="font_family_nunito">
                  No task for the next 7 days
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SideCalendar;
