import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AddCommentForTemplate,
  DeleteCommentReply,
  TasksCommentDelete,
  TasksCommentList,
  TemplateReply,
} from "../../../ApiServices/dashboardHttpService/dashboardHttpServices";
import moment from "moment";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectUserData } from "../../app/slice/userSlice";

const Comments = () => {
  const [commentList, setCommentList] = useState([]);
  const [reply, setReply] = useState(false);
  const [replyMsg, setReplyMsg] = useState("");
  const [comment, setComment] = useState("");
  const userData = useSelector(selectUserData);
  const [localId, setLocalId] = useState(null);
  const navigate = useNavigate();

  let templateId = localStorage.getItem("templete_Id");

  useEffect(() => {
    getCommentLists();
  }, []);

  const getCommentLists = async () => {
    const local_id = localStorage.getItem("myot_admin_id");
    setLocalId(local_id);
    try {
      let { data } = await TasksCommentList(templateId);
      console.log(data);
      if (!data?.error) {
        setCommentList(data?.results?.commentDetails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReplyDelete = async (e, id) => {
    e.preventDefault();
    let { data } = await DeleteCommentReply(id);
    if (data && !data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "Reply deleted",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      getCommentLists();
    }
  };

  const toggleReply = (index) => {
    setReply((prevState) => ({
      ...Object.fromEntries(
        Object.entries(prevState).map(([key]) => [key, false])
      ),
      [index]: !prevState[index],
    }));
  };

  const handleDeleteComment = async (e, id) => {
    e.preventDefault();
    try {
      let { data } = await TasksCommentDelete(id);
      if (data && !data.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Comment deleted successfully",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        getCommentLists();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addComment = async (e) => {
    e.preventDefault();
    if (comment.trim().length <= 0) {
      Swal.fire({
        toast: true,
        icon: "warning",
        position: "top-end",
        title: "Please enter comment",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      return false;
    }
    let { data } = await AddCommentForTemplate({
      comment,
      templete_Id: templateId,
      creator_Id: localId,
    });
    console.log(data);
    if (!data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "New comment added",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      getCommentLists();
      setComment("");
    }
  };

  const handleSubmit = async (e, comment_Id) => {
    e.preventDefault();
    if (replyMsg.trim().length <= 0) {
      Swal.fire({
        toast: true,
        icon: "warning",
        position: "top-end",
        title: "Please enter reply",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      return false;
    }
    let { data } = await TemplateReply({
      text: replyMsg,
      comment_Id,
      creator_Id: localId,
    });
    console.log(data);
    if (data && !data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "Reply added",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      setReplyMsg("");
      setReply(false);
      // document.getElementById("reset").click();
      getCommentLists();
    }
  };
  return (
    <div className="container px-4 text-center min-vh-100 ">
      {/* <p className="templates-leave mt-3  d-flex ">Comments</p> */}
      {commentList && commentList?.length > 0 ? (
        commentList?.map((comments, index) => (
          <>
            <div className="bg-white rounded p-2 mb-3">
              <div className="d-flex  justify-content-between">
                <div className="d-flex justify-content-between">
                  <img
                    src={
                      comments?.creator_Id?.profile_Pic
                        ? comments?.creator_Id?.profile_Pic
                        : "/images/dashboard/Avatar.png"
                    }
                    alt=""
                    className="m-2 w_20_h_20"
                  />
                  <p className="commenter-name m-auto text-capitalize">
                    {comments?.creator_Id?.name}
                  </p>
                  <p className="comment-time m-auto">
                    {moment(comments?.createdAt).calendar()}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div
                    className="cursor_pointer d-flex align-items-center"
                    onClick={() => toggleReply(index)}
                  >
                    {reply[index] ? (
                      <Link className="ticket-link me-1 text-decoration-none">
                        Cancel
                      </Link>
                    ) : (
                      <>
                        <img
                          src="/images/dashboard/reply-arrow.svg"
                          className="me-1"
                        />
                        <Link className="ticket-link me-1 text-decoration-none">
                          Reply
                        </Link>
                      </>
                    )}
                  </div>
                  {comments?.creator_Id?._id === localId ? (
                    <div
                      onClick={(e) => handleDeleteComment(e, comments?._id)}
                      className="ms-2"
                    >
                      <img
                        src="/images/icons/delete_icon.png"
                        className="me-1"
                      />
                      <Link className="ticket-link me-1 text-decoration-none text-danger">
                        Delete
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
              <p className="comment-txt p-2 mb-0">{comments?.comment}</p>

              {comments?.replyText && (
                <div
                  style={{ borderLeft: "2px solid #f8f9fa" }}
                  className="text-start ms-5"
                >
                  {comments?.replyText?.map((reply) => (
                    <div className="bg-white p-2 mb-3 d-flex align-items-start justify-content-between">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                className="w_20_h_20 me-1"
                                src={reply?.creator_Id?.profile_Pic}
                                alt=""
                              />
                              <p className="commenter-name my-auto text-capitalize">
                                {reply?.creator_Id?.name}
                              </p>
                              <p className="comment-time m-auto">
                                {moment(reply?.date).calendar()}
                              </p>
                            </div>
                          </div>
                          <p className="comment-txt p-2 mb-0">{reply?.text}</p>
                        </div>
                      </div>
                      {localId === reply?.creator_Id?._id && (
                        <div
                          className="text-danger cursor_pointer text-nowrap"
                          onClick={(e) => handleReplyDelete(e, reply?._id)}
                        >
                          <img
                            src="/images/icons/delete_icon.png"
                            className="me-1"
                          />
                          <Link className="ticket-link me-1 text-decoration-none text-danger">
                            Delete
                          </Link>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {reply[index] && (
                <div className="bg-white rounded p-2 my-3 task_reply">
                  <form onSubmit={(e) => handleSubmit(e, comments?._id)}>
                    <div className="d-flex justify-content-between">
                      <img
                        src={userData?.profile_Pic}
                        alt=""
                        className="comment-avatar m-auto mt-2 w_20_h_20"
                      />
                      <textarea
                        type="text"
                        className="p-2 w-100 mx-2 comment-txt"
                        name="reply"
                        placeholder="Reply..."
                        defaultValue=""
                        onChange={(e) => setReplyMsg(e.target.value)}
                      />
                      <button type="submit" className="reply-btn">
                        Reply
                      </button>
                      <button type="reset" id="reset" className="d-none">
                        reset
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </>
        ))
      ) : (
        <>
          <h3 className="bg-white rounded p-2 py-4 mb-3">No Comments Found</h3>
        </>
      )}

      <div className=" bg-white rounded p-2 mb-3">
        <div className="row">
          <div className="col-1">
            <img
              src={userData?.profile_Pic}
              alt=""
              className="comment-avatar m-auto mt-2 w_50_h_50"
            />
          </div>
          <div className="col-10">
            <textarea
              name="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add a comment…"
              onFocus={() => {
                if (replyMsg.trim().length === 0) setReply(false);
              }}
              id=""
              className="m-2 w-100 p-2 text-area rounded-3"
            ></textarea>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <button
              onClick={() => navigate(-1)}
              className="btn mt-2 me-2 rounded-2 bg-danger"
              style={{ width: "100px" }}
            >
              <span className="text-center">Discard</span>
            </button>
            <button
              onClick={addComment}
              className="btn mt-2 me-2 rounded-2"
              style={{ width: "100px" }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
