import React, { useEffect, useState } from "react";
import { SuperAdminCompanyActivityList, SuperAdminCompanyNotification } from "../../ApiServices/superadminHttpServices/superadminHttpServices";
import moment from "moment";
const RightSidebar = () => {
  const [data, setdata] = useState([]);
  const [activity, setActivity] = useState([])
  const [numberOfActivityToShow, setNumberOfActivityToShow] = useState(8);
  const [currentActivity, setCurrentActivity] = useState(0);

  const adminListing = async () => {
    let { data } = await SuperAdminCompanyNotification();
    console.log(data);
    if (!data?.error) {
      setdata(data?.results?.list);
    }
  };

  const adminActivity = async ()=>{
    try {
      let { data } = await SuperAdminCompanyActivityList();
      console.log(data);
      if (!data?.error) {
        setActivity(data?.results?.list)
      }        
    } catch (error) {
      console.log(error.message)
    }
  }
  useEffect(() => {
    adminListing();
    adminActivity()
  }, []);

  return (
    <>
      <div className="container right-sidebar">
        <div className="row">
          <div className="col">
            <ul className="nav nav-pills flex-column  ">
              {/* <span className="right-sidebar-heading mt-2 mb-1">Notifications</span> */}
              <div className="row bg-white">
                <div className="col float-start ">
                  <h4 className="text-align-left float-start current-day">
                    Notifications
                  </h4>
                </div>
                <div className="col float-end">
                  <div className=" float-end me-1 ">
                    <img
                      src="/images/chatbox/More-dots.svg"
                      alt=""
                      className="me-2"
                    />
                  </div>
                </div>
              </div>

              {data && data?.length > 0 ? (
                data?.slice(0, 5)?.map((noti, i) => (
                  <>
                    <li className="nav-item text-dark my-1 py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <img src="/images/dashboard/pdf-icon.svg" />
                          {/* <img src="/images/sidebar/Featured Icon.png" /> */}
                        </div>

                        <div className=" right_side_bar">{noti?.title}</div>
                        <p className="sidebar-time flex-end">
                          {moment(noti?.createdAt).format("DD/MM")}
                        </p>
                      </div>
                    </li>
                  </>
                ))
              ) : (
                <p className="text-center">No notifications</p>
              )}
            </ul>

            <ul className="nav nav-pills flex-column my-4 ">
              {/* <span className="right-sidebar-heading mt-3 mb-3">Activities</span> */}
              <div className="row bg-white">
                <div className="col float-start py-2">
                  <h4 className="text-align-left float-start current-day">
                    Activities
                  </h4>
                </div>
                <div className="col float-end">
                  <div className=" float-end pt-2">
                    {activity?.length <= 8
                      ? activity?.length
                      : numberOfActivityToShow}{" "}
                    of {activity?.length}
                  </div>
                </div>
                {/* <div className="col float-end">
                    <div className=" float-end me-1 mt-4">
                      <img
                        src="/images/chatbox/More-dots.svg"
                        alt=""
                        className="me-2"
                      />
                    </div>
                  </div> */}
              </div>

              {activity && activity?.length > 0 ? (
                activity
                  ?.slice(currentActivity, numberOfActivityToShow)
                  ?.map((activity) => (
                    <li className="nav-item text-dark my-1 py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            src="/images/icons/activity.png"
                            className="w_20_h_20"
                          />
                          {/* <img src="/images/sidebar/Avatar1.png" /> */}
                        </div>

                        <div className=" right_side_bar">
                          {" "}
                          {activity?.title}
                        </div>
                        <p className="sidebar-time flex-end">
                          {moment(activity?.createdAt).format("DD/MM")}
                        </p>
                      </div>
                    </li>
                  ))
              ) : (
                <p className="text-center">No activity found</p>
              )}

              {activity?.length > 8 && (
                <>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "15px" }}
                  >
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev - 8);
                        setNumberOfActivityToShow((prev) => prev - 8);
                      }}
                      disabled={currentActivity <= 0}
                      className="w-100 rounded-3"
                    >
                      <span className="fs-4">«</span>
                    </button>
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev + 8);
                        setNumberOfActivityToShow((prev) => prev + 8);
                      }}
                      disabled={currentActivity + 8 >= activity.length}
                      className="w-100 rounded-3"
                    >
                      <span className="fs-4">»</span>
                    </button>
                  </div>
                </>
              )}

              {/* {notifications?.length > 8 && (
                  <>
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev + 8);
                        setNumberOfActivityToShow((prev) => prev + 8);
                        console.log(currentActivity, numberOfActivityToShow);
                      }}
                      disabled={
                        currentActivity + numberOfActivityToShow >=
                        notifications.length
                      }
                      className="w-100"
                    >
                      Show More
                    </button>
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev - 8);
                        setNumberOfActivityToShow((prev) => prev - 8);
                        console.log(currentActivity, numberOfActivityToShow);
                      }}
                      disabled={
                        notifications?.length <= 8 || currentActivity <= 8
                      }
                      className="w-100"
                    >
                      Previous
                    </button>
                  </>
                )} */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
