import React from "react";
import NewsLetter from "./NewsLetter";

const LandingFooter = () => {
  return (
    <>
      <section className="footer_main">
        <NewsLetter />

        <div className="footer_mid">
          <img className="big_logo" src="/assets/img/logo_big.svg" alt />
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footermenus">
                  <h2>Product </h2>
                  <a href="javascript:;">Features</a>
                  <a href="javascript:;">Pricing</a>
                  <a href="javascript:;">Integrations</a>
                  <a href="javascript:;">Product Updates</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footermenus">
                  <h2>Resources</h2>
                  <a href="javascript:;">Blog</a>
                  <a href="javascript:;">Help Center</a>
                  <a href="javascript:;">Tutorials</a>
                  <a href="javascript:;">API Documentation</a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footermenus">
                  <h2>Company</h2>
                  <a href="javascript:;">About Us</a>
                  <a href="javascript:;">Careers</a>
                  <a href="javascript:;">Contact Us</a>
                  <a href="javascript:;">Legal</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 px-0">
                <div className="footerother_links">
                  <div className="copyright">
                    © 2024 MYOT. All rights reserved
                  </div>
                  <div className="other_links">
                    <a href="javascript:;">Privacy Policy</a>
                    <a href="javascript:;">Terms of Service</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="social_media">
                  <a href="javascript:;">
                    <img src="/assets/img/linkden.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/facebook.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/twitter.svg" alt />
                  </a>
                  <a href="javascript:;">
                    <img src="/assets/img/instagram.svg" alt />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingFooter;
