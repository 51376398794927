import React from 'react'
import LandingFooter from './LandingFooter'
import LandingNavbar from './LandingNavbar'
import KeyBenefits from './KeyBenefits'
import MoreBenefits from './MoreBenefits'
import Pricing from './Pricing'
import Testimonials from './Testimonials'
import { Link } from 'react-router-dom'

const LandingHome = () => {
  return (
    <>
    <LandingNavbar />
    <>
      <section className="banner_sections">
        <div className="baanner_img">
          <img
            className="baanner_img1"
            src="/assets/img/topbox.svg"
            alt="i"
          />
          <img
            className="baanner_img2"
            src="/assets/img/banner_img.svg"
            alt="i"
          />
        </div>
        <div className="container">
          <div className="col-md-6">
            <div className="banner_content">
              <h1>
                Go Paperless &amp; Simplify Your Document Workflows with MYOT
              </h1>
              <p>
                No more printing, no more troubleshooting printers, no need to
                scan or digitize documents. Manage everything digitally with
                MYOT.
              </p>
              <Link to='/contact' className="comman_btn">
                Request a Demo
                <img src="/assets/img/arrow-right.svg" alt="i" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="whychosenew">
        <div className="container">
          <div className="whychosenew_text">
            <img src="/assets/img/logo.png" alt="i" />
            <h2>Why Choose MYOT?</h2>
            <p>
              MYOT is the ultimate document management platform, enabling your
              business to transition smoothly to a paperless environment.
              Here’s why MYOT is the right choice for your business.
            </p>
          </div>
        </div>
      </section>

      <section className="whychose_myot">
        <KeyBenefits />
      </section>

      {/* <section class="whychose_myot"> */}
       
        <MoreBenefits />
      {/* </section> */}

      <section className="pricing_sec" id="pricing_sec">
        <Pricing />
      </section>

      <section className="testimonials">
        <Testimonials />
      </section>

      <section className="revolutionize">
        <div className="container">
          <div className="revolutionize-wrapper">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className>
                  <h3 className="text-white">
                    Ready to Revolutionize Your Document Workflows?
                  </h3>
                </div>
                <div className="d-flex align-items-center gap-2 gap-md-3 flex-wrap mt-4 mt-md-0">
                  <Link to='/contact'
                    className="bg-white text-main comman_btn py-2 h-fit h6 fw-semibold"
                  >
                    Demo
                  </Link>
                  <a  href="#pricing_sec"
                    className="bg-white text-main comman_btn py-2 h-fit h6 fw-semibold"
                  >
                    See Plans
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-block">
          <img
            src="./assets/img/Group-icon.png"
            alt="i"
            className="revolutionize-icon-img"
          />
        </div>
      </section>
    </>

    <LandingFooter />
  </>
  )
}

export default LandingHome
