import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LandingNavbar = () => {
  const [active, setActive] = useState("home");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      setActive("home");
    }
    if (location.pathname === "/contact") {
      setActive("contact");
    }

    // Smooth scroll to section if hash is present
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [location.pathname, location.hash]);

  const scrollToPricing = (e) => {
    e.preventDefault();
    // Redirect to homepage with hash
    navigate("/#pricing_sec");
  };

  return (
    <>
      <header className="header_main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-3 col-md-1">
              <div className="header_logo">
                <Link to="/">
                  <img src="/assets/img/logo.svg" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-9 col-md-6 px-0 d-none d-md-block">
              <div className="header_menus">
                <Link
                  className={`${
                    active === "contact" ? "text-white" : "text-dark"
                  }`}
                  to="/"
                >
                  Why Myot
                </Link>
                <a
                  className={`${
                    active === "contact" ? "text-white" : "text-dark"
                  }`}
                  href="#pricing_sec"
                  onClick={scrollToPricing}
                >
                  Pricing
                </a>
                <Link
                  className={`${
                    active === "contact" ? "text-white" : "text-dark"
                  }`}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-9 col-md-5">
              <div className="d-flex align-items-center justify-content-end gap-2 gap-md-0">
                <div className="col-auto">
                  <div className="dropdown language_drop">
                    <button
                      className
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {active === "contact" ? (
                        <img src="/assets/img/Vector_white.png" alt="" />
                      ) : (
                        <img src="/assets/img/lang.svg" alt />
                      )}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a className="language_a" href="javascript:;">
                        EN
                      </a>
                      <a className="language_a" href="javascript:;">
                        AR
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-auto px-2 px-md-4">
                  <Link to={'/Login'}
                    className={`login_btn ${
                      active === "contact" ? "text-white" : "text-dark"
                    }`}
                  >
                    Login
                  </Link>
                </div>
                <div className="d-block d-md-none">
                  <div className="side-bar" onclick="sideBarToggle()">
                    <i className="fa fa-bars h5 mt-1 mb-0" />
                  </div>
                </div>
                <div className="col-auto d-none d-md-block">
                  <Link to='/contact' className="header_btn" href="javascript:;">
                    Request a demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="mobile-header-content">
        <div className="mobile-header">
          <div className="d-flex justify-content-end mt-4 px-4">
            <div className="p-2 rounded-circle" onclick="CloseSidebar()">
              <i className="fa fa-close fs-3" />
            </div>
          </div>
          <ul className="list-group px-3 mt-1">
            <li className="list-group-item border-0">
              <a href className="text-decoration-none text-dark fw-semibold h5">
                Why Myot
              </a>
            </li>
            <li className="list-group-item border-0">
              <a href="#pricing_sec" className="text-decoration-none text-dark fw-semibold h5">
                Pricing
              </a>
            </li>
            <li className="list-group-item border-0">
              <a
                href="/contact"
                className="text-decoration-none text-dark fw-semibold h5"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div className="mt-4 w-fit px-4">
            <a className="header_btn px-4" href="javascript:;">
              Request a demo
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingNavbar;
