import React, { useEffect, useState } from "react";
import Comments from "../Common/Template/Comments";
import Sidebar from "../Sidebar";
import TopbarLayout from "../Topbar/TopbarLayout";
import RightSidebar from "../RightSidebar";
import Footer from "../Common/Footer/Footer";

const TemplateComments = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 sidebar">
            <Sidebar />
          </div>
          <div className="col-7 middle-content bg-body-tertiary p-0 min-vh-100">
            <div className="container-fluid border-bottom sticky-top bg-white mb-4">
              <nav className="row header bg-white  ">
                <TopbarLayout />
              </nav>
            </div>

            <Comments />

            <div className="footer bg-white">
              <Footer />
            </div>
          </div>

          <div className="col">
            <RightSidebar />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateComments;
