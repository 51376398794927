import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { selectUserData } from "../../app/slice/userSlice";
import { DeleteDocumentCommentReply, DocumentComment, DocumentCommentLists, DocumentCommentReply, RequestCommentDelete } from "../../../ApiServices/dashboardHttpService/dashboardHttpServices";

const DocComments = () => {
  const [commentList, setCommentList] = useState([]);
  const [reply, setReply] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [comment, setComment] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [localId, setLocalId] = useState();
  const userData = useSelector(selectUserData);
  const navigate = useNavigate();

  let document_Id = localStorage.getItem("document_id");

  useEffect(() => {
    getCommentLists();
  }, []);

  const getCommentLists = async () => {
    try {
      let lid = localStorage.getItem("myot_admin_id");
      setLocalId(lid);
      let { data } = await DocumentCommentLists(document_Id);
      if (!data?.error) {
        setCommentList(data?.results?.commentDetailsList);
        console.log(data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteComment = async (e, id) => {
    e.preventDefault();
    try {
      let { data } = await RequestCommentDelete(id);
      if (!data?.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Comment deleted successfully",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        getCommentLists();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toggleReply = (index) => {
    setReply((prevState) => ({
      ...Object.fromEntries(
        Object.entries(prevState).map(([key]) => [key, false])
      ),
      [index]: !prevState[index],
    }));
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    if (replyMsg === "") {
      Swal.fire({
        toast: true,
        icon: "warning",
        position: "top-end",
        title: "Please enter a reply",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      return false;
    }
    let formData = {
      creator_Id: localId,
      comment_Id: id,
      text: replyMsg,
    };
    console.log(formData);
    let { data } = await DocumentCommentReply(formData);
    console.log(data);
    if (!data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "New comment added",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      document.getElementById("reset").click();
      setNewReply("");
      setReply(false);
      getCommentLists();
    }
  };

  const addComment = async (e) => {
    e.preventDefault();

    if (comment.trim().length <= 0) {
      Swal.fire({
        toast: true,
        icon: "warning",
        position: "top-end",
        title: "Please enter comment",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      return false;
    }

    let { data } = await DocumentComment({
      comment,
      creator_Id: localId,
      document_Id: document_Id,
    });
    console.log(data);
    if (!data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "New comment added",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      getCommentLists();
      setComment("");
    }
  };
  const handleReplyDelete = async (e, id) => {
    e.preventDefault();
    let { data } = await DeleteDocumentCommentReply(id);
    if (data && !data?.error) {
      Swal.fire({
        toast: true,
        icon: "success",
        position: "top-end",
        title: "Reply deleted",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
      getCommentLists();
    }
  };
  return (
    <div className="container px-4 text-center min-vh-100 ">
      {commentList && commentList?.length > 0 ? (
        commentList?.map((comments, index) => (
          <>
            <div className="bg-white rounded p-2 mb-3">
              <div className="d-flex  justify-content-between">
                <div className="d-flex justify-content-between">
                  <img
                    src={
                      comments?.creator_Id?.profile_Pic
                        ? comments?.creator_Id?.profile_Pic
                        : "/images/dashboard/Avatar.png"
                    }
                    alt=""
                    className="m-2 w_20_h_20 border"
                  />
                  <p className="commenter-name m-auto text-capitalize">
                    {comments?.creator_Id?.name}
                  </p>
                  <p className="comment-time m-auto">
                    {moment(comments?.createdAt).calendar()}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div
                    className="cursor_pointer d-flex align-items-center"
                    onClick={() => toggleReply(index)}
                  >
                    {reply[index] ? (
                      <Link className="ticket-link me-1 text-decoration-none">
                        Cancel
                      </Link>
                    ) : (
                      <>
                        <img
                          src="/images/dashboard/reply-arrow.svg"
                          className="me-1"
                          alt="reply"
                        />
                        <Link className="ticket-link me-1 text-decoration-none">
                          Reply
                        </Link>
                      </>
                    )}
                  </div>
                  {localId === comments?.creator_Id?._id ? (
                    <div
                      onClick={(e) => handleDeleteComment(e, comments?._id)}
                      className="ms-2"
                    >
                      <img
                        src="/images/icons/delete_icon.png"
                        className="me-1"
                        alt="del"
                      />
                      <Link className="ticket-link me-1 text-decoration-none text-danger">
                        Delete
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
              <p className="comment-txt p-2 mb-0">{comments?.comment}</p>

              {comments?.replyText && (
                <div
                  style={{ borderLeft: "2px solid #f8f9fa" }}
                  className="text-start ms-5"
                >
                  {comments?.replyText.map((reply) => (
                    <div className="bg-white p-2 mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              className="w_20_h_20 me-1"
                              src={reply?.creator_Id?.profile_Pic}
                              alt=""
                            />
                            <p className="commenter-name my-auto text-capitalize">
                              {reply?.creator_Id?.name}
                            </p>
                            <p className="comment-time m-auto">
                              {moment(reply?.date).calendar()}
                            </p>
                          </div>
                          <p className="comment-txt w-100 p-2 mb-0">
                            {reply?.text}
                          </p>
                        </div>
                        {localId === reply?.creator_Id?._id && (
                          <div
                            className="text-danger cursor_pointer ms-2 text-nowrap"
                            onClick={(e) => handleReplyDelete(e, reply?._id)}
                          >
                            <img
                              src="/images/icons/delete_icon.png"
                              className="me-1"
                              alt="delete"
                            />{" "}
                            Delete
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {reply[index] && (
                <div className="bg-white rounded p-2 my-3 task_reply">
                  <form onSubmit={(e) => handleSubmit(e, comments?._id)}>
                    <div className="d-flex justify-content-between">
                      <img
                        src={userData?.profile_Pic}
                        alt=""
                        className="comment-avatar border m-auto mt-2 w_20_h_20"
                      />
                      <textarea
                        type="text"
                        className="p-2 w-100 mx-2 comment-txt"
                        name="reply"
                        placeholder="Reply..."
                        defaultValue=""
                        onChange={(e) => setReplyMsg(e.target.value)}
                      />
                      <button type="submit" className="reply-btn">
                        Reply
                      </button>
                      <button type="reset" id="reset" className="d-none">
                        reset
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </>
        ))
      ) : (
        <>
          <h3 className="bg-white rounded p-2 py-4 mb-3">No Comments Found</h3>
        </>
      )}

      <div className=" bg-white rounded p-2 mb-3">
        <div className="row">
          <div className="col-1">
            <img
              src={userData?.profile_Pic}
              alt=""
              className="comment-avatar border m-auto mt-2 w_50_h_50"
            />
          </div>
          <div className="col-10">
            <textarea
              name="comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add a comment…"
              onFocus={() => {
                if (replyMsg.trim().length === 0) setReply(false);
              }}
              id=""
              className="m-2 w-100 p-2 text-area rounded-3"
            ></textarea>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <button
              onClick={() => navigate(-1)}
              className="btn mt-2 me-2 rounded-2 bg-danger"
              style={{ width: "100px" }}
            >
              <span className="text-center">Discard</span>
            </button>
            <button
              onClick={addComment}
              className="btn mt-2 me-2 rounded-2"
              style={{ width: "100px" }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocComments;
