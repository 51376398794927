import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonials = () => {
  const testimonials = [
    {
      imgSrc: "assets/img/clients.png",
      quote:
        "I couldn't be more satisfied with the experience. From the moment I reached out, their team demonstrated outstanding.",
      name: "Brian Clark",
      position: "VP of Marketing at Snapchat",
    },
    {
      imgSrc: "assets/img/clients.png",
      quote:
        "The support team was always available, friendly, and eager to assist with any questions or concerns.",
      name: "Stephanie Powell",
      position: "VP of Sales at SalesForce",
    },
    {
      imgSrc: "assets/img/clients.png",
      quote:
        "Everything was completed on time, with regular updates to keep me informed of progress. This level of communication reassured.",
      name: "Christopher White",
      position: "VP of Design at Spotify",
    },
    {
      imgSrc: "assets/img/clients.png",
      quote:
        "The team showcased in-depth knowledge of their field, providing valuable insights and recommendations.",
      name: "Taylor Turner",
      position: "VP of HR at Netflix",
    },
  ];

  const settings = {
    loop: true,
    nav: true,
    margin: 10,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      500: {
        items: 2,
        nav:false,
      },
      768: {
        items: 3,
        nav: true,
      },
      992: {
        items: 3,
        nav: true,
      },
    },
  };

  return (
    <div className="cont">
      <div className="row">
        <div className="col-md-12">
          <div className="heading_comman">
            <h2 className="text-white">What our clients have to say</h2>
            <p className="text-white">
              Streamline workflows and gain clear visibility across teams to{" "}
              <br />
              make strategic decisions with confidence.
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <OwlCarousel
            className="client_slidder owl-carousel"
            {...settings}
          >
            {testimonials.map((testimonial, index) => (
              <div className="client_box mx-2" key={index}>
                <div className="client_pic">
                  <img src={testimonial.imgSrc} alt="" />
                </div>
                <div className="client_content">
                  <p>{testimonial.quote}</p>
                  <strong>{testimonial.name}</strong>
                  <span>{testimonial.position}</span>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
