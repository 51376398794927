import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasksList: [],
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    taskList(state, action) {
      state.tasksList = action.payload;
    },
  },
});

export default taskSlice.reducer;
export const {taskList} = taskSlice.actions;