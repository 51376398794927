import adminHttpService from "../adminHttpService";
import Swal from "sweetalert2";

export async function NewTicketList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/new-tickets`
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}
export async function GetFAQ() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/faqs-list`
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}
export async function GetFAQById(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/faqs-details/${id}`
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}
export async function UpdateFAQById(id,formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-faqs/${id}`,
      formData
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}

export async function CreateFAQ(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/create-faqs`,
      formData
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}
export async function DeleteFAQById(id) {
  try {
    const { data } = await adminHttpService.delete(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/faqs-delete/${id}`
    );
    return { data }; // Return an object with both data and error properties
  } catch (error) {
    if (error.response) {
      console.error(error.response.data.message);
      return { data: null, error: error.response.data.message }; // Return an object with both data and error properties
    }
    return { data: null, error: "An unexpected error occurred." }; // Return an object with both data and error properties
  }
}

export async function SuperAdminNewTicket(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/create-ticket`,
      formData
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function ViewEmpTickets(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/tickets-view/${id}`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function ChangeTicketsStatus(id, formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/change-tickets-sttaus/${id}`, formData
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function SuperAdminCompanyUpdateActivity(id, requestData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/company-edit/${id}`,
      requestData
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function TicketsReply(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/on-tickets-reply`,
      formData
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperAdminTicketList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/all-tickets-list`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperAdminOngoingTicketList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/on-going-ticket`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperAdminNewTicketList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/new-tickets`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperAdminResolvedTicketList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/solve-ticktes`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperadminPrivacyFetch(endpoint) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/content-details/65b0a0b46dcaaf68cc29b7dd`
    );
    if (!data?.error) return { data };

    throw data.error;
  } catch (error) {
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      title: error?.response?.data?.message,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });
    return { error };
  }
}
export async function SuperAdminCompanyActivity() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/count-company`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function SuperAdminProfile(id) {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/getAdminData/${id}`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function UpdateAdminProfile(formData) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/updateProfile`,
      formData
    );

    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}
export async function SuperadminPrivacyPost(authToken, requestBody) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-Content/65b0a0b46dcaaf68cc29b7dd`,
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token-admin': authToken,
        },
      }
    );
    if (!data?.error) return { data };

    throw data.error;
  } catch (error) {
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      title: error?.response?.data?.message,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });
    return { error };
  }
}

export async function SuperadminTermsPost(authToken, requestBody) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-Content/65b0aacac60c73eec4b4cdad`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token-admin': authToken,
      },
    }

    );
    if (!data?.error) return { data };

    throw data.error;
  } catch (error) {
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      title: error?.response?.data?.message,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });
    return { error };
  }
}

export async function SuperAdminCompanyNotification() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/list`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperAdminCompanyActivityList() {
  try {
    const { data } = await adminHttpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/activity-admin`
    );
    return { data };
  } catch (error) {
    if (error.response)
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response.data.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    return { error };
  }
}

export async function SuperadminAboutPost(authToken, requestBody) {
  try {
    const { data } = await adminHttpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/api/admin/edit-Content/65b0a0896dcaaf68cc29b7db`, requestBody, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token-admin': authToken,
      },
    }

    );
    if (!data?.error) return { data };

    throw data.error;
  } catch (error) {
    Swal.fire({
      toast: true,
      icon: "error",
      position: "top-end",
      title: error?.response?.data?.message,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });
    return { error };
  }
}





