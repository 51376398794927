import React, { useState } from "react";
import SideBarEmpl from "./SideBarEmpl";
import NotificatioModal from "../RightSidebarCommon/NotificatioModal";
import PrevNextLocation from "../Topbar/PrevNextLocation";
import RightSidebarEmply from "./RightSidebarEmply";
import FaqComp from "../FAQs/FaqComp";
import { Link } from "react-router-dom";



const EmployeeFAQ = () => {
    const [modal, setModal] = useState(false);
  const [faqContent, setFaqContent] = useState([
    {
      title: "What is MYOT?",
      description:
        "MYOT is a platform that provides various tools and resources to help you manage your tasks and projects efficiently.",
    },
    {
      title: "How do I create an account?",
      description:
        "You can create an account by clicking on the 'Sign Up' button on the top right corner and filling out the registration form.",
    },
    {
      title: "What features does MYOT offer?",
      description:
        "MYOT offers features such as task management, project tracking, collaboration tools, and reporting analytics to help you stay organized and productive.",
    },
    {
      title: "Is there a mobile app available?",
      description:
        "Yes, MYOT is available on both iOS and Android platforms. You can download the app from the respective app stores.",
    },
    {
      title: "How can I contact support?",
      description:
        "You can contact support by clicking on the 'Support' link at the bottom of the page or by emailing support@myot.com.",
    },
  ]);

  const closeModal = () => {
    setModal(false);
  };
  // 
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 sidebar">
          <SideBarEmpl />
        </div>
        <div className="col-7 middle-content">
          <div className="container-fluid border-bottom sticky-top bg-white mb-4">
          <div className="container-fluid border-bottom sticky-top bg-white mb-4">
              <nav className="row header bg-white  ">
                <ul className="col align-items-center mt-3">
                  <li className="nav-item dropdown-hover d-none d-lg-block">
                    <a className="nav-link  fw-bold ms-2">Employee FAQ</a>
                  </li>
                </ul>
                <div className="col d-flex align-items-center  justify-content-end">
                  {/* <form class="" role="search">
                    <input
                      class="form-control search-bar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => {
                        setSearchData(e.target.value);
                        //  handleSearch();
                      }}
                      value={searchData.searchTerm}
                    />
                  </form> */}
                  <div className="">
                    {/* <img
                      src="/images/dashboard/announcement.png"
                      alt=""
                      className="ms-4 "
                    /> */}
                    <Link to={"/Employee/Chat"}>
                      <img
                        src="/images/dashboard/chat-left-dots-fill.png"
                        alt=""
                        className="ms-4"
                      />
                    </Link>
                    <img
                      src="/images/dashboard/round-notifications.png"
                      alt=""
                      className="ms-4 cursor_pointer position-relative"
                      onClick={() => setModal(!modal)}
                    />

                    {modal && (
                      <div className="position-absolute top-0 end-0 mt-5">
                        <NotificatioModal closeModal={closeModal} />
                      </div>
                    )}
                  </div>
                </div>
                <PrevNextLocation />
              </nav>
            </div>
          </div>

          <FaqComp faqContent={faqContent} />
          <div className="footer">
            <div>© 2023 MYOT</div>
            <div className="d-flex ">
              <p className="ms-3">About</p>
              <p className="ms-3">Support</p>
              <p className="ms-3">Contact Us</p>
            </div>
          </div>
        </div>

        <div className="col">
          <RightSidebarEmply />
        </div>
      </div>
    </div>
  );
};

export default EmployeeFAQ;
