import React, { useEffect, useState } from "react";
import "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import {
  CompanyList,
  CompanyListView,
  MainDashboardCount,
  acceptPermission,
  rejectedPermission,
} from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import RightSidebar from "./RightSidebar";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import MiddleHeader from "./middleHeader";
import { Checkbox } from "rsuite";
import MainSidebar from "./sidebar";
import BackTask from "./BackTask";
import Footer from "../Common/Footer/Footer";
const SuperAdminDashboard = () => {
  const [showClearButton, setShowClearButton] = useState(false);
  const [adminCount, setAdminCount] = useState();
  const [data, setdata] = useState()
  const [templates, setTemplates] = useState({
    columns: [
      {
        label: "Company Name",
        field: "name",
        sort: false,
        width: 50,
        selected: false,
      },
      {
        label: "Company Type",
        field: "type",
        width: 50,
        selected: false,
      },
      {
        label: "Address",
        field: "address",
        width: 100,
        selected: false,
      },
      {
        label: "Email",
        field: "email",
        width: 50,
        selected: false,
      },
      {
        label: "Actions",
        field: "actions",
        width: 100,
        selected: false,
      },
    ],
    rows: [],
    hiddenColumns: [],
    selectedColumns: [],
    sortColumnName: "name",
    sortType: "asc",
  });

  useEffect(() => {
    getCompanyListing();
    getAdminCount();
  }, []);

  const getAdminCount = async () => {
    let { data } = await MainDashboardCount();
    if (!data.error) {
      setAdminCount(data?.results);
    }
  };

  const getCompanyViewList = async (id) => {
    try {

      let { data } = await CompanyListView(id)
      setdata(data?.results);
      console.log(data?.results?.companyDetails?.[0] || {})
    } catch (error) {
      console.log(error);
    }
  }
  const getCompanyListing = async () => {
    let { data } = await CompanyList();

    if (!data.error) {
      const newRows = [];
      let values = data.results.list;

      values?.map((list, index) => {
        const returnData = {};
        if (list?.permission === "Accepted") {

          returnData.name = list?.companyName;
          returnData.type = list?.companyType;
          returnData.email = list?.email;
          returnData.address = list?.address;
          returnData.mobile = list?.mobileNumber;

          returnData.actions = (
            <div class="text-center">
              <a
                className="cursor_pointer"
                type=""
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src="/images/sidebar/ThreeDots.svg" className="w-auto" />
              </a>
              <ul class="dropdown-menu border-0 shadow p-3 mb-5 rounded">
                <li>
                  <Link
                    type="button"

                    class="dropdown-item"
                    data-bs-toggle="modal" data-bs-target="#view"
                    onClick={() => getCompanyViewList(list?._id)}

                  >

                    <img
                      src="/images/users/AddressBook.svg"
                      alt=""
                      className="me-2"
                    />
                    View Details
                  </Link>
                </li>
                <li onClick={() => accept(list?._id)}>
                  {/* <a class="dropdown-item border-bottom" href="#">
                    <img
                      src="/images/users/PencilLine.svg"
                      alt=""
                      className="me-2"
                    />
                    Approve
                  </a> */}
                </li>

                <li className="cursor_pointer" onClick={() => reject(list?._id)}>
                  <a class="dropdown-item">
                    <img src="/images/XCircle.svg" alt="" className="me-2" />
                    Reject
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        newRows.push(returnData);
      });
      setTemplates({ ...templates, rows: newRows });
    }
  };

  const accept = async (id) => {
    try {
      let { data } = await acceptPermission(id);
      if (!data.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Approved successfully",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        getAdminCount();
        getCompanyListing();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const reject = async (id) => {
    try {
      let { data } = await rejectedPermission(id);
      if (!data.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Rejected successfully",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        getAdminCount();
        getCompanyListing();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSortOrder = () => {
    const currentSortType = templates.sortType === "asc" ? "desc" : "asc";
    const sortedRows = [...templates.rows].sort((a, b) => {
      let comparison = 0;
      if (a.name < b.name) {
        comparison = -1;
      } else if (a.name > b.name) {
        comparison = 1;
      }
      return currentSortType === "asc" ? comparison : comparison * -1;
    });

    setTemplates({
      ...templates,
      rows: sortedRows,
      sortType: currentSortType,
    });
  };

  const handleCheckboxChange = (field) => {
    let updatedSelectedColumns = [...templates.selectedColumns];
    const index = updatedSelectedColumns.indexOf(field);
    if (index > -1) {
      updatedSelectedColumns.splice(index, 1);
    } else {
      updatedSelectedColumns.push(field);
    }
    setTemplates({ ...templates, selectedColumns: updatedSelectedColumns });
  };

  const hideSelectedColumns = () => {
    const updatedHiddenColumns = [
      ...templates.hiddenColumns,
      ...templates.selectedColumns,
    ];
    setTemplates({
      ...templates,
      hiddenColumns: updatedHiddenColumns,
      selectedColumns: [],
    });
    setShowClearButton(true);
  };

  const columnsWithCheckboxes = templates?.columns.map((column) => ({
    ...column,
    label: (
      <div key={column.field} className="t_head_box">
        <Checkbox
          checked={templates.selectedColumns.includes(column.field)}
          onChange={() => handleCheckboxChange(column.field)}
          className="me-1 mt-1"
          defaultChecked
        ></Checkbox>
        <span
          className="mdb_table_header"
          onClick={() => handleSort(column.field)}
        >
          {column.label}
          {column.field !== "comments" && column.field !== "actions" && (
            <span className="ms-3">
              <img src="/images/icons/sort.png" alt="" />
            </span>
          )}
        </span>
      </div>
    ),
  }));
  const handleSort = (field) => {
    const data = templates.rows.slice();
    let sortType = "asc";
    if (field === templates.sortColumnName) {
      sortType = templates.sortType === "asc" ? "desc" : "asc";
    }
    data.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];
      if (field === "assigned") {
        valueA =
          a?.assigned?.props?.children[1]?.props?.children?.toLowerCase();
        valueB =
          b?.assigned?.props?.children[1]?.props?.children?.toLowerCase();
      } else if (field === "status") {
        valueA = a?.status?.props?.children[1]?.props?.children?.toLowerCase();
        valueB = b?.status?.props?.children[1]?.props?.children?.toLowerCase();
      }
      // Compare values
      if (valueA < valueB) {
        return sortType === "asc" ? -1 : 1;
      } else if (valueA > valueB) {
        return sortType === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
    setTemplates({
      ...templates,
      rows: data,
      sortColumnName: field,
      sortType: sortType,
    });
  };
  const visibleColumns = columnsWithCheckboxes.filter(
    (column) => !templates.hiddenColumns.includes(column.field)
  );

  const showAllColumns = () => {
    setTemplates({ ...templates, hiddenColumns: [], selectedColumns: [] });
    setShowClearButton(false);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 sidebar">
            <MainSidebar />
          </div>
          <div className="col-7 middle-content">
            <div className="container-fluid border-bottom sticky-top bg-white mb-4">
              <nav className="row header bg-white align-items-center ">
                <ul className="col align-items-center mt-3">
                  <li className="nav-item dropdown-hover d-none d-lg-block">
                    <a className="nav-link fw-bold">Main / Dashboard</a>
                  </li>
                </ul>
                <div className="col d-flex align-items-center  justify-content-end mb-3">
                  <MiddleHeader />
                </div>

              </nav>
<BackTask/>
            </div>

            <div className="col-12 mb-4">
              <div className="row statics_part">
                <div className="col-lg-3 col-md-6 mb-md-2">
                  <div className="statics_box card-clr-1-3">
                    <div className="statics_left">
                      <h6 className="mb-0 header-card-text">Total Users</h6>
                    </div>
                    <div className=" mt-4">
                      <h3 className="card-text-count mb-0 fw-semibold fs-7">
                        {adminCount?.totalCompany[0]?.count}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-md-2 ">
                  <div className="statics_box card-clr-2-4">
                    <div className="statics_left">
                      <h6 className="mb-0 header-card-text">
                        Approved Users
                      </h6>
                    </div>
                    <div className=" mt-4">
                      <h3 className="card-text-count mb-0 fw-semibold fs-7">
                        {adminCount?.totalApprovedCount[0].count}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-md-2 ">
                  <div className="statics_box card-clr-1-3">
                    <div className="statics_left">
                      <h6 className="mb-0 header-card-text">
                        Rejected Users
                      </h6>
                    </div>
                    <div className=" mt-4">
                      <h3 className="card-text-count mb-0 fw-semibold fs-7">
                        {adminCount?.totalRejectedCount[0]?.count || 0}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-md-2 ">
                  <div className="statics_box card-clr-2-4">
                    <div className="statics_left">
                      <h6 className="mb-0 header-card-text">Open Tickets</h6>
                    </div>
                    <div className=" mt-4">
                      <h3 className="card-text-count mb-0 fw-semibold fs-7 ">
                        {(adminCount?.totalOpenTickets &&
                          adminCount?.totalOpenTickets[0]?.count) ||
                          0}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="position-relative mb-3">
              <p className="table-name mb-2" style={{ color: "white" }}>.</p>
              <div className=" col-12 d-flex align-items-center table-searchbar">
                <div className="d-flex ">
                  <div className="col-md-3 table-searchbar-imgs">
                    <img
                      onClick={toggleSortOrder}
                      src="/images/dashboard/ArrowsDownUp.png"
                      className="p-2 table-searchbar-img border-end cursor_pointer"
                    />
                  </div>
                  <div className="d-flex ms-2 align-items-center justify-content-around table-searchbar-txt">
                    <p className="m-0 text-nowrap">
                      {templates?.selectedColumns &&
                        templates?.selectedColumns.length}
                      <span> Selected</span>
                    </p>
                    {showClearButton ? (
                      <p
                        className="hide-selected m-0 text-nowrap cursor_pointer "
                        onClick={showAllColumns}
                      >
                        Clear Selection
                      </p>
                    ) : (
                      <p
                        className="hide-selected m-0 ms-2 text-nowrap cursor_pointer "
                        onClick={hideSelectedColumns}
                      >
                        Hide Selected
                      </p>
                    )}
                  </div>
                  <div class="search_icon">
                    <img
                      width={20}
                      src={require("../../assets/logo/search.png")}
                    ></img>
                  </div>
                </div>
                <form className="d-flex me-2" role="search"></form>
              </div>
              <div className="col-12 mdb_table mt-3 ">
                <div className="table-responsive">
                  <MDBDataTable
                    bordered
                  displayEntries={true}
                    entries={10}
                    className="text-nowrap"
                    hover
                    data={{ ...templates, columns: visibleColumns }}
                    noBottomColumns
                    paginationLabel={"«»"}
                    sortable={false}
                    searching
                  />
                </div>
              </div>
            </div>



            <div className="footer">
              <Footer />
            </div>
          </div>
          <div className="col">
            <RightSidebar />
          </div>

          {/* Comment Modal */}
          <div
            class="modal fade"
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title th-text fs-5" id="exampleModalLabel">
                    Add comment
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeForm"
                  ></button>
                </div>
                <div class="modal-body">
                  <form className="rounded" >
                    <div className="mb-3 border-bottom">
                      <label className="form-label th-text">
                        Comment or type
                      </label>
                      <input
                        type="text"
                        className="form-control border-0 w-100"
                        placeholder="Type comment..."
                      />
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <img
                          src="/images/tasks/assign comments.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/mention.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/task.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/emoji.svg"
                          alt=""
                          className="comment-img"
                        />
                        <img
                          src="/images/tasks/attach_attachment.svg"
                          alt=""
                          className="comment-img"
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="comment-btn btn-primary"
                        >
                          Comment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* Comment Modal close */}

          {/*  */}
          <div
            class="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">
                    Template Details
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade comman_modal" id="view" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="comman_modal_inner">
                <div class="comman_modal_head">
                  <h2>Company Information</h2>
                </div>
                <div class="company_view">
                  <div class="company_detailss">
                    <strong>Name :</strong> {data?.companyDetails?.[0]?.companyName}
                  </div>
                  <div class="company_detailss">
                    <strong>Email :</strong> {data?.companyDetails?.[0]?.email}
                  </div>
                  <div class="company_detailss">
                    <strong>Member Size :</strong> {data?.companyDetails?.[0]?.companySize}
                  </div>
                  <div class="company_detailss">
                    <strong>Address :</strong> {data?.companyDetails?.[0]?.address}
                  </div>
                  <div class="company_detailss">
                    <strong>Company Type :</strong> {data?.companyDetails?.[0]?.companyType}
                  </div>
                  
                  <div class="company_detailss">
                    <strong>Phone Number :</strong> {data?.companyDetails?.[0]?.mobileNumber}
                  </div>
                      
                  <div class="company_detailss">
                    <strong>Company Active Users :</strong> {data?.totalActiveUser?.totalUser || 0}
                  </div>
                  
                  <div class="company_detailss">
                    <strong>Company In-Active Users :</strong> {data?.totalUser?.totalUser || 0}
                  </div>

                  <div class="col-12 text-end d-flex align-items-center justify-content-end mt-5">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      className="btn mt-2 me-2 rounded-2"
                      href="javascript:;"
                      data-bs-dismiss="modal"

                      style={{ width: "100px" }}
                    >
                      Cancel
                    </button>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashboard;
