import React, { useState } from "react";
import PrevNextLocation from "../Topbar/PrevNextLocation";
import SideBarEmpl from "./SideBarEmpl";
import { Link } from "react-router-dom";
import NotificatioModal from "../RightSidebarCommon/NotificatioModal";
import CommonAnnoucement from "../Common/Annoucement/CommonAnnoucement";
import RightSidebarEmply from "./RightSidebarEmply";

const EmpAnnouncements = () => {
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 sidebar">
            <SideBarEmpl />
          </div>
          <div className="col-7 middle-content p-0 min-vh-100">
            <div className="container-fluid  sticky-top bg-white mb-4">
              <nav className="row header bg-white  ">
                <ul className="col align-items-center mt-3">
                  <li className="nav-item dropdown-hover d-none d-lg-block">
                    <a
                      className="nav-link fw-bold ms-2"
                      href="/Employee/profile"
                    >
                      My Profile / Edit
                    </a>
                  </li>
                </ul>
                <div className="col-7 d-flex align-items-center  justify-content-end">
                  {/* <form className="" role="search">
                    <input
                      className="form-control search-bar"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </form> */}
                  <div className="">
                    {/* <img
                      src="/images/dashboard/announcement.png"
                      alt=""
                      className="ms-4 "
                    /> */}
                    <Link to={"/Employee/Chat"}>
                      <img
                        src="/images/dashboard/chat-left-dots-fill.png"
                        alt=""
                        className="ms-4"
                      />
                    </Link>
                    <img
                      src="/images/dashboard/round-notifications.png"
                      alt=""
                      className="ms-4 cursor_pointer position-relative"
                      onClick={() => setModal(!modal)}
                    />

                    {modal && (
                      <div className="position-absolute top-0 end-0 mt-5">
                        <NotificatioModal closeModal={closeModal} />
                      </div>
                    )}
                  </div>
                </div>
                <PrevNextLocation />
              </nav>
            </div>

            <CommonAnnoucement />
          </div>
          <div className="col">
            <RightSidebarEmply />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpAnnouncements;
