import React, { useState } from "react";
import Slider from "react-slick";

const MoreBenefits = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 536,
            settings: {
              slidesToShow: 2,
            },
          },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };

  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: false,
    arrows:false
  };

  return (
    <>
      <section className="benefits_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading_comman text-center">
                <h2 className="text-white">
                  More/Alternative benefits: <br />
                  We pick the best among all
                </h2>
              </div>
            </div>

            <div className="slick1 mb-4">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                {...settings}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_1.png" alt="i" />
                  </div>
                  <span>
                    Create <br /> Template
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_2.png" alt="i" />
                  </div>
                  <span>
                    Edit <br /> Template
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_3.png" alt="i" />
                  </div>
                  <span>
                    Request <br /> Document
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_4.png" alt="i" />
                  </div>
                  <span>
                    Group <br /> Chat
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/report.png" alt="i" />
                  </div>
                  <span>
                    Report as <br /> Admin<t span></t>
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_5.png" alt="i" />
                  </div>
                  <span>
                    Bulk Upload <br />
                    Employees
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_6.png" alt="i" />
                  </div>
                  <span>
                    Sign <br />
                    Document
                  </span>
                </div>
                <div className="slider-nav-box">
                  <div className="slider_icon">
                    <img src="/assets/img/icon_7.png" alt="i" />
                  </div>
                  <span>
                    Approve <br /> Template
                  </span>
                </div>
              </Slider>
            </div>

            <Slider {...settings2} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
              <div className="slider-for-box">
                <div className="sliderbig_img text-center d-flex align-items-center justify-content-center">
                  <img src="/assets/img/benefits_1.png" alt="i" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoreBenefits;
