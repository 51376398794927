import React from "react";
import { Link } from "react-router-dom";

const NewsLetter = () => {
  return (
    <>
      <div className="footer_top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="footerabout">
                <a href="index.html">
                  <img src="/assets/img/logo.svg" alt />
                </a>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page.
                </p>
                <Link to='/contact' className="comman_btn">
                  Get Started <img src="/assets/img/arrow-right.svg" alt />
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="newsletter">
                <form action="#">
                  <div className="form-group position-relative">
                    <label htmlFor>Subscribe to our newsletter</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter your email"
                    />
                    <button type="submit" className="defalt_btn">
                      Default
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
