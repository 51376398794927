import React, { useEffect } from "react";
import LandingFooter from "./LandingFooter";
import LandingNavbar from "./LandingNavbar";
import { Button } from "rsuite";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { AddContactForSuperAdmin } from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import Swal from "sweetalert2";

const LandingContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    trim: true,
  });

  const onSubmit = async (info) => {
    try {
      let { data } = await AddContactForSuperAdmin({
        name: info?.name,
        email: info?.email,
        message: info?.msg,
        company: info?.cName,
        mobileNumber: info?.number,
      });
      if (data && !data?.error) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Your message has been sent successfully!",
          showConfirmButton: false,
          timer: 3000,
        });

        await document.getElementById("resetForm").click();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <LandingNavbar />

      <section className="contactus">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="contactus_content pe-md-5">
                <h1>
                  Let us know <br /> what you think!
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipiscing eli mattis
                  sit phasellus mollis sit aliquam sit nullam.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contactus_form">
                <form className="row" onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group col-md-6">
                    <label htmlFor>Name</label>
                    <input
                      type="text"
                      placeholder="Your Name..."
                      className={classNames("form-control", {
                        "is-invalid": errors.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                        maxLength: {
                          value: 30,
                          message: "Name cannot exceed 30 characters",
                        },
                      })}
                    />
                    {errors.name && (
                      <small className="errorText mt-1 ">
                        {errors.name?.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor>Email</label>
                    <input
                      type="text"
                      placeholder="Your Email..."
                      className={classNames("form-control", {
                        "is-invalid": errors.email,
                      })}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="errorText mt-1 ">
                        {errors.email?.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor>Phone</label>
                    <input
                      type="number"
                      placeholder="Contact Number..."
                      className={classNames("form-control", {
                        "is-invalid": errors.number,
                      })}
                      {...register("number", {
                        required: "Number is required",
                        minLength: {
                          value: 9,
                          message: "Number must be 9 digits",
                        },
                        maxLength: {
                          value: 9,
                          message: "Number must be 9 digits",
                        },
                      })}
                    />
                    {errors.number && (
                      <small className="errorText mt-1 ">
                        {errors.number?.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor>Company</label>
                    <input
                      type="text"
                      placeholder="Your Company Name..."
                      className={classNames("form-control", {
                        "is-invalid": errors.cName,
                      })}
                      {...register("cName", {
                        required: "Company Name is required",
                        maxLength: {
                          value: 30,
                          message: "Company Name cannot exceed 30 characters",
                        },
                      })}
                    />
                    {errors.cName && (
                      <small className="errorText mt-1 ">
                        {errors.cName?.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor>Message</label>
                    <textarea
                      name
                      id
                      placeholder="Type your message here..."
                      className={classNames("form-control", {
                        "is-invalid": errors.msg,
                      })}
                      {...register("msg", {
                        required: "Message is required",
                        minLength: {
                          value: 10,
                          message: "Message contains atleast 10 characters",
                        },
                        maxLength: {
                          value: 200,
                          message: "Message cannot exceed 200 characters",
                        },
                      })}
                    />
                    {errors.msg && (
                      <small className="errorText mt-1 ">
                        {errors.msg?.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <button
                      className="send_msg"
                      type="submit"
                      disabled={!isValid}
                    >
                      Send Message <img src="assets/img/arrowright.png" alt />
                    </button>
                    <button type="reset" className="d-none" id="resetForm">
                      reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LandingFooter />
    </>
  );
};

export default LandingContactUs;
