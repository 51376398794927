import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import "./Responsive.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
import AuthLoader from "./Components/Loader/AuthLoader";
import LoaderAprv from "./Components/Loader/LoaderAprv";
import LoaderEmply from "./Components/Loader/LoaderEmply";

import DocumentViewAprv from "./Components/Approver/DocumentViewAprv";
import SuperAdminDashboard from "./Components/superAdmin/SuperAdminDashboard";
import io from "socket.io-client";

import ProtectedRoutes from "./utils/ProtectedRoutes";
import SigFaq from "./Components/Signatory/SigFaq";
import ApproverFaq from "./Components/Approver/ApproverFaq";
import EmployeeFAQ from "./Components/Employee/EmployeeFAQ";
import TemplateComments from "./Components/Index/TemplateComments";
import DocumentComments from "./Components/Index/DocumentComments";
import EmpAnnouncements from "./Components/Employee/EmpAnnouncements";
import Transaction from "./Components/Index/Trans/Transaction";
import LandingHome from "./Components/LandingWebsite/LandingHome";
import LandingContactUs from "./Components/LandingWebsite/LandingContact";
import EditorNew from "./Components/CkEditor/EditorNew";
import RegistrationSuccess from "./Components/LandingWebsite/RegistrationSuccess";
// import DepartmentEditor from "./Components/CkEditor/DepartmentEditor";
const SignEditor = lazy(() =>
  import("./Components/Signatory/Requests/SignEditor")
);
const RejectedDocs = lazy(() => import("./Components/Signatory/RejectedDocs"));
const Guidelines = lazy(() =>
  import("./Components/Index/Guidelines/Guidelines")
);
const AuthforgotPass = lazy(() => import("./Components/Login/AuthforgotPass"));
const AuthforgotSuccess = lazy(() =>
  import("./Components/Login/AuthforgotSuccess")
);
const Home = lazy(() => import("./Components/Index/Home"));
const Dashboard = lazy(() => import("./Components/Index/Dashboard"));
const Tasks = lazy(() => import("./Components/Index/Tasks"));
const Users = lazy(() => import("./Components/Index/Users"));
const Departments = lazy(() => import("./Components/Index/Departments"));
const DepartmentView = lazy(() => import("./Components/Index/DepartmentView"));
const Chatbox = lazy(() => import("./Components/Index/Chatbox"));

const Calendar = lazy(() => import("./Components/Calendar/Calendar"));

const HomeDept = lazy(() => import("./Components/DepartmentManager/HomeDept"));
const ViewPdf = lazy(() => import("./Components/ViewPdf/ViewPdf"));
const DashboardDept = lazy(() =>
  import("./Components/DepartmentManager/DashboardDept")
);

const TaskDept = lazy(() => import("./Components/DepartmentManager/Tasks"));
const Template = lazy(() => import("./Components/DepartmentManager/Template"));
const Certificate = lazy(() =>
  import("./Components/DepartmentManager/Certificate")
);
const CalenderDept = lazy(() =>
  import("./Components/DepartmentManager/CalenderDept")
);
const DepartmentsDept = lazy(() =>
  import("./Components/DepartmentManager/DepartmentsDept")
);
const CertificateView = lazy(() =>
  import("./Components/DepartmentManager/CertificateView")
);
const TemplateView = lazy(() =>
  import("./Components/DepartmentManager/TemplateView")
);
const HomeAprv = lazy(() => import("./Components/Approver/HomeAprv"));
const TemplateReview = lazy(() =>
  import("./Components/Approver/TemplateReview")
);
const DashboardAprv = lazy(() => import("./Components/Approver/DashboardAprv"));
const CreateTemplate = lazy(() =>
  import("./Components/TemplateTool/RichTextEditor")
);
const EditTemplate = lazy(() =>
  import("./Components/TemplateTool/EditTemplate")
);

const Editor = lazy(() => import("./Components/TemplateTool/Editor"));
const TemplateRevView = lazy(() =>
  import("./Components/Approver/TemplateRevView")
);
const HistoryLog = lazy(() => import("./Components/Approver/HistoryLogAprv"));
const CalenderAprv = lazy(() => import("./Components/Approver/CalenderAprv"));
const CalendarMonthAprv = lazy(() =>
  import("./Components/Approver/CalendarMonthAprv")
);
const CalendarYearAprv = lazy(() =>
  import("./Components/Approver/CalenderYearAprv")
);
const HistoryLogAprv = lazy(() =>
  import("./Components/Approver/HistoryLogAprv")
);
const HistoryLogReject = lazy(() =>
  import("./Components/Approver/HistoryLogReject")
);
const HomeSig = lazy(() => import("./Components/Signatory/HomeSig"));
const DashboardSig = lazy(() => import("./Components/Signatory/DashboardSig"));
const HistoryLogSig = lazy(() =>
  import("./Components/Signatory/HistoryLogSig")
);

const PdfSign = lazy(() => import("./Components/ReactPdfSign/PdfSign"));
const SignDocuments = lazy(() =>
  import("./Components/Signatory/Requests/SignDocuments")
);
const Approver = lazy(() => import("./Components/Index/Approver"));
const Signatories = lazy(() => import("./Components/Index/Signatories"));
const Requests = lazy(() => import("./Components/Index/Requests"));
const Announcements = lazy(() => import("./Components/Index/Announcements"));
const Analytics = lazy(() => import("./Components/Index/Analytics"));
const RequestsAprv = lazy(() => import("./Components/Approver/RequestsAprv"));
const AnnouncementsAprv = lazy(() =>
  import("./Components/Approver/AnnouncementsAprv")
);
const RequestsDept = lazy(() =>
  import("./Components/DepartmentManager/RequestsDept")
);
const AnnouncementsDept = lazy(() =>
  import("./Components/DepartmentManager/AnnouncementsDept")
);
const AnalyticsDept = lazy(() =>
  import("./Components/DepartmentManager/Analytics")
);
const RequestsSig = lazy(() => import("./Components/Signatory/Requests"));
const AnnouncementsSig = lazy(() =>
  import("./Components/Signatory/AnnouncementsSig")
);
const ReportAnalytics = lazy(() =>
  import("./Components/superAdmin/reportAnalytics")
);
const ContactRequests = lazy(() =>
  import("./Components/superAdmin/ContactRequests")
);
const Profile = lazy(() => import("./Components/Index/Profile"));
const EditProfile = lazy(() => import("./Components/Index/EditProfile"));
const EditProfileSig = lazy(() => import("./Components/Signatory/EditProfile"));
const ProfileSig = lazy(() => import("./Components/Signatory/ProfileSig"));
const ProfileAprv = lazy(() => import("./Components/Approver/ProfileAprv"));
const EditProfileAprv = lazy(() =>
  import("./Components/Approver/EditProfileAprv")
);
const ProfileDept = lazy(() =>
  import("./Components/DepartmentManager/ProfileDept")
);
const EditProfileDept = lazy(() =>
  import("./Components/DepartmentManager/EditProfileDept")
);
const Help = lazy(() => import("./Components/Index/Help"));
const ContactUs = lazy(() => import("./Components/Index/ContactUs"));
const HelpSig = lazy(() => import("./Components/Signatory/HelpSig"));
const ContactUsSig = lazy(() => import("./Components/Signatory/ContactUsSig"));
const HelpAprv = lazy(() => import("./Components/Approver/HelpAprv"));

const ContactUsAprv = lazy(() => import("./Components/Approver/ContactUsAprv"));
const HelpDept = lazy(() => import("./Components/DepartmentManager/HelpDept"));
const HelpSupportDept = lazy(() =>
  import("./Components/DepartmentManager/Help&supportDept")
);
const ContactUsDept = lazy(() =>
  import("./Components/DepartmentManager/ContactUsDept")
);
const Settings = lazy(() => import("./Components/Index/Settings"));
const Comments = lazy(() => import("./Components/Common/Template/Comments"));
const SignManagement = lazy(() =>
  import("./Components/Signatory/SignManagement")
);
const SettingsAprv = lazy(() => import("./Components/Approver/SettingsAprv"));
const HomeEmpl = lazy(() => import("./Components/Employee/HomeEmpl"));
const EmployeeDash = lazy(() => import("./Components/Employee/EmployeeDash"));
const RequestHistoryEmpl = lazy(() =>
  import("./Components/Employee/RequestHistoryEmpl")
);
const DocHistoryEmploye = lazy(() =>
  import("./Components/Employee/DocHistoryEmploye")
);
const DocumentEmply = lazy(() => import("./Components/Employee/DocumentEmply"));
const ReceivedDocEmpl = lazy(() =>
  import("./Components/Employee/ReceivedDocEmpl")
);
const CalenderEmply = lazy(() => import("./Components/Employee/CalenderEmply"));
const ViewDocumentEmply = lazy(() =>
  import("./Components/Employee/ViewDocumentEmply")
);
const ViewReceivedDoc = lazy(() =>
  import("./Components/Employee/ViewRecievedDoc")
);
const EmplProfile = lazy(() => import("./Components/Employee/ProfileEmpl"));
const EmplHelp = lazy(() => import("./Components/Employee/Help"));

const SuperAdminEditProfile = lazy(() =>
  import("./Components/superAdmin/EditProfile")
);
const EmplEditProfile = lazy(() =>
  import("./Components/Employee/EmplEditProfile")
);
const ChatboxEmploy = lazy(() => import("./Components/Employee/ChatboxEmploy"));
const ContactUsEmpl = lazy(() => import("./Components/Employee/ContactUsEmpl"));
const EmplAuthLogin = lazy(() => import("./Components/Login/EmplAuthLogin"));
const EmplAuthforgotPassword = lazy(() =>
  import("./Components/Login/EmplAuthforgotPass")
);
const EmplForgotSuccess = lazy(() =>
  import("./Components/Login/EmplForgotSuccess")
);

const SuperAdminProfile = lazy(() => import("./Components/superAdmin/Profile"));
const ViewUser = lazy(() => import("./Components/Index/ViewUser"));
const EditUserProfile = lazy(() =>
  import("./Components/Index/EditUserProfile")
);
const TemplateEdit = lazy(() =>
  import("./Components/DepartmentManager/TemplateEdit")
);
const ChatboxSig = lazy(() => import("./Components/Signatory/ChatboxSig"));
const ChatboxDept = lazy(() =>
  import("./Components/DepartmentManager/Chatbox")
);
const ChatboxAprv = lazy(() => import("./Components/Approver/Chatbox"));
const CalendarMonthDept = lazy(() =>
  import("./Components/DepartmentManager/CalendarMonthDept")
);
const CalenderYearDept = lazy(() =>
  import("./Components/DepartmentManager/CalenderYear")
);
const CommentsDept = lazy(() =>
  import("./Components/DepartmentManager/CommentsDept")
);
const CompanyRegistration = lazy(() =>
  import("./Components/superAdmin/companyRegistration")
);
const TransactionManagement = lazy(() =>
  import("./Components/superAdmin/Transaction")
);
const AuthResetPass = lazy(() => import("./Components/Login/AuthResetPass"));
const AuthSignUp = lazy(() => import("./Components/Login/AuthSignUp"));
const EmplySetting = lazy(() => import("./Components/Employee/EmplySetting"));
const OTPauthEmply = lazy(() => import("./Components/Login/OTPauthEmply"));
const CalendarMonthEmply = lazy(() =>
  import("./Components/Employee/CalendarMonthEmply")
);
const CalenderYearEmply = lazy(() =>
  import("./Components/Employee/CalenderYearEmply")
);
const Taskss = lazy(() => import("./Components/Signatory/Tasks"));
const PdfEdit = lazy(() => import("./Components/DepartmentManager/PdfEdit"));
const CommentsEmply = lazy(() => import("./Components/Employee/CommentsEmply"));
// const SigComments = lazy(() =>
//   import("./Components/Signatory/Comments/Comments")
// );
const SettingsSig = lazy(() => import("./Components/Signatory/SettingsSig"));
const UpdatePassword = lazy(() => import("./Components/Login/UpdatePassword"));
const UpdatePassEmply = lazy(() =>
  import("./Components/Login/UpdatePassEmply")
);
const CommentsAprv = lazy(() => import("./Components/Approver/CommentsAprv"));
const Login = lazy(() => import("./Components/superAdmin/login"));
const MainForgotPass = lazy(() =>
  import("./Components/superAdmin/forgotPassword")
);
const MainUpdatePassword = lazy(() =>
  import("./Components/superAdmin/updatePassword")
);
const MainForgotSuccess = lazy(() =>
  import("./Components/superAdmin/forgotSucess")
);
const TaskEmply = lazy(() => import("./Components/Employee/TaskEmply"));
const TaskApprover = lazy(() => import("./Components/Approver/TaskApprover"));
const MainHome = lazy(() => import("./Components/superAdmin/home"));
const SigDocComments = lazy(() =>
  import("./Components/Signatory/Comments/SigDocComments")
);
const ViewTemp = lazy(() =>
  import("./Components/Signatory/ViewTemplate/ViewTemp")
);

const SettingsDept = lazy(() =>
  import("./Components/DepartmentManager/SettingsDept")
);
const HelpNSupport = lazy(() =>
  import("./Components/superAdmin/Help/HelpNSupport")
);
const ContentManagement = lazy(() =>
  import("./Components/superAdmin/contentManagement")
);
const EditContent = lazy(() => import("./Components/superAdmin/editContent"));
const Templates = lazy(() => import("./Components/Index/Templates"));
const Loader = lazy(() => import("./Components/Loader/Loader"));
const AuthLogin = lazy(() => import("./Components/Login/AuthLogin"));
const FaqsIndex = lazy(() => import("./Components/Index/FaqsIndex"));
const FAQ = lazy(() => import("./Components/superAdmin/FAQ"));
// import io from 'socket.io-client';
const DepartmentFAQ = lazy(() =>
  import("./Components/DepartmentManager/DepartmentFAQ")
);
const CKEditor = lazy(() => import("./Components/CkEditor/Editor"));
function App() {
  const [socket, setSocket] = useState(null);
  const token = localStorage.getItem("token-company");

  useEffect(() => {
    let myot_login_id = localStorage.getItem("myot_admin_id");
    // setUserLoginId(myot_login_id);

    const newSocket = io(`${process.env.REACT_APP_APIENDPOINT}`, {
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      console.log("socket_id", newSocket.id);
      newSocket.emit("createRoom", myot_login_id);
    });

    setSocket(newSocket);
    return () => {
      console.log("dis");
      newSocket.disconnect();
      console.log(newSocket);
    };
  }, []);

  return (
    <div>
      <ToastContainer />
      <Routes>
        {/* LANDING ROUTES START */}
        <Route exact path="/" element={<LandingHome />} />
        <Route exact path="*" element={<LandingHome />} />
        <Route exact path="/contact" element={<LandingContactUs />} />
        {/* LANDING ROUTES END */}
        {/* <Route exact path="/dept/editor" element={<CKEditor />} /> */}
        <Route exact path="/dept/editor" element={<EditorNew />} />
        {/* <Route
          path="*"
          element={
            <Suspense fallback={<AuthLoader />}>
              <AuthLogin />
            </Suspense>
          }
        /> */}

        <Route
          path="Login"
          element={
            <Suspense fallback={<AuthLoader />}>
              <AuthLogin />
            </Suspense>
          }
        />
        <Route
          path="Forgot-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <AuthforgotPass />
            </Suspense>
          }
        />
        <Route
          path="Update-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <UpdatePassword />
            </Suspense>
          }
        />
        <Route
          path="Forgot-success"
          element={
            <Suspense fallback={<AuthLoader />}>
              <AuthforgotSuccess />
            </Suspense>
          }
        />

        <Route path="/success" element={<RegistrationSuccess />} />
        <Route
          path="/Admin/Signup"
          element={
            <Suspense fallback={<AuthLoader />}>
              <AuthSignUp />
            </Suspense>
          }
        />

        <Route
          path="main/Update-Profile"
          element={
            <Suspense fallback={<AuthLoader />}>
              <SuperAdminEditProfile />
            </Suspense>
          }
        />

        <Route
          path="Admin/Home"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Dashboard"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Tasks"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Tasks />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Calendar"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Calendar />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Users"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Users />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Users/View-user-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewUser />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Users/Edit-user-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditUserProfile />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Users/View-user-profile/document"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewPdf />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Requests/View-Document"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewPdf />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Department"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Departments />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Department/View"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DepartmentView />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Chat"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Chatbox socket={socket} />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Approvers"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Approver />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Signatories"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Signatories />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Requests"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Requests />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Announcements"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Announcements />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Analytics"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Analytics />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Profile/Edit-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditProfile />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Settings"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Settings />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Faqs"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <FaqsIndex />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Faqs"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ApproverFaq />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Employee/Faqs"
          element={
            // <ProtectedRoutes>
            <Suspense fallback={<Loader />}>
              <EmployeeFAQ />
            </Suspense>
            // </ProtectedRoutes>
            //
          }
        />

        <Route
          path="Admin/Guidelines"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Guidelines />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Template/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <TemplateComments />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Requests/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DocumentComments />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Help"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Help />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Help/Contact-us"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ContactUs />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Templates />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Admin/Template/View-template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewTemp />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Admin/Transaction-Management"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Transaction />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        {/* DEPARTMENT ROUTES */}

        <Route
          path="Department/Home"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HomeDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Dashboard"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DashboardDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Template />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template-view"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <TemplateView />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Request/:id"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <PdfEdit />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Department/Certificate-view/:id"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CertificateView />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template-edit/:id"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <TemplateEdit />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template/Create-Template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CreateTemplate />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template/Edit-Template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditTemplate />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Department/Requests/Edit-Template"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Editor />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Certificate"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Certificate />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Certificate-view"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CertificateView />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Calendar"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Calendar />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Calendar-month"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CalendarMonthDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Tasks"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <TaskDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Department/Calendar-year"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CalenderYearDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Departments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DepartmentsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Requests"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <RequestsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Chat"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Chatbox socket={socket} />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Template/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <CommentsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Announcements"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <AnnouncementsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Analytics"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <AnalyticsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/My-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ProfileDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Edit-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditProfileDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Help"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HelpDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Settings"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SettingsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Help-Support"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HelpSupportDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Contact-us"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ContactUsDept />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        {/* SUPER ADMIN ROUTES  */}

        <Route
          path="/main/Company-Registration"
          element={
            <Suspense fallback={<Loader />}>
              <CompanyRegistration />
            </Suspense>
          }
        />
        <Route
          path="/main/Transaction-Management"
          element={<TransactionManagement />}
        />
        <Route
          path="main/Dashboard"
          element={
            <Suspense fallback={<Loader />}>
              <SuperAdminDashboard />
            </Suspense>
          }
        />
        <Route
          path="main/FAQ"
          element={
            <Suspense fallback={<Loader />}>
              <FAQ />
            </Suspense>
          }
        />

        {/* APRROVER ROUTES */}

        <Route
          path="Approver/Home"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <HomeAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Dashboard"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <DashboardAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Template-review"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <TemplateReview />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Template-view"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <TemplateRevView />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Tasks"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <TaskApprover />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Document-view"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <DocumentViewAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Requests"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <RequestsAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Announcements"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <AnnouncementsAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/History-log-approved"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <HistoryLogAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/History-log-rejected"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <HistoryLogReject />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Approver/Calendar"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Calendar />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Calendar-month"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <CalendarMonthAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Calendar-year"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <CalendarYearAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/My-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <ProfileAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Edit-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <EditProfileAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Help"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <HelpAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="Approver/Help-Support"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <HelpSupportAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="Approver/Contact-us"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <ContactUsAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Settings"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <SettingsAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Approver/Chat"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <Chatbox socket={socket} />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Approver/Comment"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<LoaderAprv />}>
                <CommentsAprv />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        {/* Signatory Routes */}

        <Route
          path="Signatory/Requests/Sign"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SignEditor />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Faqs"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SigFaq />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Department/Faqs"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DepartmentFAQ />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Requests/View-Document"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewPdf />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Home"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HomeSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Dashboard"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <DashboardSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Requests/PdfSign"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <PdfSign />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Requests/Sign-Document"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SignDocuments />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Tasks"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Tasks />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Signed-Documents"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HistoryLogSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Rejected-Documents"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <RejectedDocs />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Calendar"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Calendar />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Requests"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <RequestsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Announcements"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <AnnouncementsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ProfileSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Signature-management"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SignManagement />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Profile/Edit-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditProfileSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Help"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HelpSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Contact-us"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ContactUsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Chat"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <Chatbox socket={socket} />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Settings"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SettingsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Requests"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <RequestsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Announcements"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <AnnouncementsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/My-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ProfileSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Edit-profile"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <EditProfileSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Help"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <HelpSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        <Route
          path="Signatory/Help/Contact-us"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ContactUsSig />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="Signatory/Home/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SigComments />
              </Suspense>
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="Signatory/Requests/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SigDocComments />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        {/* <Route
          path="Signatory/Template/Comments"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <SigComments />
              </Suspense>
            </ProtectedRoutes>
          }
        /> */}
        <Route
          path="Signatory/Template/View"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewTemp />
              </Suspense>
            </ProtectedRoutes>
          }
        />
        <Route
          path="Signatory/Signed-Documents/View"
          element={
            <ProtectedRoutes>
              <Suspense fallback={<Loader />}>
                <ViewTemp />
              </Suspense>
            </ProtectedRoutes>
          }
        />

        {/* Employee Routes */}

        <Route
          path="Employee/Requests/Editor"
          element={
            <Suspense fallback={<Loader />}>
              <SignEditor />
            </Suspense>
          }
        />
        <Route
          path="Employee/Login"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplAuthLogin />
            </Suspense>
          }
        />
        <Route
          path="Employee/Forgot-Password"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplAuthforgotPassword />
            </Suspense>
          }
        />
        <Route
          path="Employee/Forgot-Success"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplForgotSuccess />
            </Suspense>
          }
        />
        <Route
          path="Employee/reset-update-password"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <UpdatePassEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/Home"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <HomeEmpl />
            </Suspense>
          }
        />
        <Route
          path="Employee/Dashboard"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmployeeDash />
            </Suspense>
          }
        />
        <Route
          path="Employee/history-log-request"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <RequestHistoryEmpl />
            </Suspense>
          }
        />
        <Route
          path="Employee/history-log-access"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <DocHistoryEmploye />
            </Suspense>
          }
        />
        <Route
          path="Employee/document"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <DocumentEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/received-doc"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <ReceivedDocEmpl />
            </Suspense>
          }
        />
        <Route
          path="Employee/Tasks"
          element={
            <Suspense fallback={<Loader />}>
              <TaskEmply />
            </Suspense>
          }
        />
        {/* <Route
          path="Employee/Calender"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <CalenderEmply />
            </Suspense>
          }
        /> */}
        <Route
          path="Employee/Calendar"
          element={
            <Suspense fallback={<Loader />}>
              {/* <Calender /> */}
              <Calendar />
            </Suspense>
          }
        />
        <Route
          path="Employee/view-details/:id"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <ViewDocumentEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/received-doc/view-details"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <ViewReceivedDoc />
            </Suspense>
          }
        />
        <Route
          path="Employee/OTP-verification"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <OTPauthEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/profile"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplProfile />
            </Suspense>
          }
        />
        <Route
          path="Employee/Edit-Profile"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplEditProfile />
            </Suspense>
          }
        />
        <Route
          path="Employee/Announcements"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmpAnnouncements />
            </Suspense>
          }
        />
        {/* <Route
          path="Employee/Help&Support"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplHelpSupport />
            </Suspense>
          }
        />  */}
        <Route
          path="Employee/Help"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplHelp />
            </Suspense>
          }
        />
        <Route
          path="Employee/Chat"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <Chatbox socket={socket} />
            </Suspense>
          }
        />
        {/* <Route
          path="Employee/Chat"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <ChatboxEmploy  />
            </Suspense>
          }
        /> */}
        <Route
          path="Employee/Contact"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <ContactUsEmpl />
            </Suspense>
          }
        />
        <Route
          path="Employee/Settings"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <EmplySetting />
            </Suspense>
          }
        />
        <Route
          path="Employee/Calendar-month"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <CalendarMonthEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/Calendar-year"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <CalenderYearEmply />
            </Suspense>
          }
        />
        <Route
          path="Employee/Comment/:id"
          element={
            <Suspense fallback={<LoaderEmply />}>
              <CommentsEmply />
            </Suspense>
          }
        />

        {/* Main Routes */}
        <Route
          path="/main/login"
          element={
            <Suspense fallback={<AuthLoader />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/main/forgot-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainForgotPass />
            </Suspense>
          }
        />
        <Route
          path="/main/update-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainUpdatePassword />
            </Suspense>
          }
        />
        <Route
          path="/main/success"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainForgotSuccess />
            </Suspense>
          }
        />
        <Route
          path="main/Company-Management"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainHome />
            </Suspense>
          }
        />
        <Route
          path="main/Contact-Requests"
          element={
            <Suspense fallback={<AuthLoader />}>
              <ContactRequests />
            </Suspense>
          }
        />
        <Route
          path="main/Report-Analytics"
          element={
            <Suspense fallback={<AuthLoader />}>
              <ReportAnalytics />
            </Suspense>
          }
        />

        <Route
          path="main/Help-support"
          element={
            <Suspense fallback={<AuthLoader />}>
              <HelpNSupport />
            </Suspense>
          }
        />
        <Route
          path="main/Profile"
          element={
            <Suspense fallback={<AuthLoader />}>
              <SuperAdminProfile />
            </Suspense>
          }
        />
        <Route
          path="/main/login"
          element={
            <Suspense fallback={<AuthLoader />}>
              <Login />{" "}
            </Suspense>
          }
        />
        <Route
          path="/main/forgot-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainForgotPass />{" "}
            </Suspense>
          }
        />
        <Route
          path="/main/update-password"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainUpdatePassword />{" "}
            </Suspense>
          }
        />

        <Route
          path="main/Content-management"
          element={
            <Suspense fallback={<AuthLoader />}>
              <ContentManagement />
            </Suspense>
          }
        />
        <Route
          path="main/Content-management/Edit-content"
          element={
            <Suspense fallback={<AuthLoader />}>
              <EditContent />
            </Suspense>
          }
        />

        <Route
          path="/main/success"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainForgotSuccess />
            </Suspense>
          }
        />
        <Route
          path="main/Home"
          element={
            <Suspense fallback={<AuthLoader />}>
              <MainHome />
            </Suspense>
          }
        />
        <Route
          path="main/Help-support"
          element={
            <Suspense fallback={<AuthLoader />}>
              <HelpNSupport />
            </Suspense>
          }
        />

        <Route
          path="main/Content-management/Edit-content"
          element={
            <Suspense fallback={<AuthLoader />}>
              <EditContent />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
