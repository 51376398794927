import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { setNotificationList } from "./app/slice/notificationSlice";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { setNotificationList } from "../app/slice/notificationSlice";
import {
  AdminNotifications,
  ReadNotifications,
} from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import SideCalendar from "../RightSidebarCommon/SideCalendar";
import {
  EmployNotifications,
  employeProfileDetail,
} from "../../ApiServices/EmployeeHttpService/employeeLoginHttpService";

const RightSidebarEmply = () => {
  // const [notifications, setNotifications] = useState([]);
  // const [activity, setActivity] = useState([]);
  const [route, setRoute] = useState("");
  const [numberOfActivityToShow, setNumberOfActivityToShow] = useState(8);
  const [numberOfNotificationToShow, setNumberOfNotificationToShow] =
    useState(6);
  const [currentActivity, setCurrentActivity] = useState(0);

  const notifications = useSelector(
    (state) => state.notification?.notificationList
  );
  const activity = useSelector((state) => state.notification?.activityList);



  const dispatch = useDispatch();
  const location = useLocation();
  const [profileDetail, setProfileDetail] = useState(null);
  const ids =
    localStorage.getItem("user_id") || localStorage.getItem("myot_admin_id");

  useEffect(() => {
    if (location?.pathname.startsWith("/Employee")) {
      setRoute("Employee");
    } else if (location?.pathname.startsWith("/Approver")) {
      setRoute("Approver");
    }
  }, [location]);

  useEffect(() => {
    const details = async () => {
      const detailResults = await employeProfileDetail(ids);
      const data = detailResults?.[0]?.results?.employee?.employRole;
      setProfileDetail(data);
    };
    details();
  }, []);
  console.log(profileDetail);

useEffect(()=>{
  getNotifications()
},[route])

  const getNotifications = async () => {
    let id = await localStorage.getItem("user_id");
    const { data } = await EmployNotifications(id);
    console.log(data)

    if (data && !data?.error) {
      let val = data?.results?.list;
      console.log(val);
      let filterData = await val.filter((item) => item.role === route);
      console.log(filterData)
      console.log(filterData);
      dispatch(setNotificationList(filterData));
    }
  }

  console.log(route);
  const handleRead = async (id) => {
    try {
      let { data } = await ReadNotifications(id);
      if (data && !data?.error) {
        console.log(data);
        // getNotifications()
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container right-sidebar">
        <div className="row">
          <div className="col">
            <ul className="nav nav-pills flex-column  ">
              {/* <span className="right-sidebar-heading mt-2 mb-1">Notifications</span> */}
              <div className="row bg-white">
                <div className="col float-start ">
                  <h4 className="text-align-left float-start current-day">
                    Notifications
                  </h4>
                </div>
                {/* <div className="col float-end">
                  <div className=" float-end me-1 ">
                    <img
                      src="/images/chatbox/More-dots.svg"
                      alt=""
                      className="me-2"
                    />
                  </div>
                </div> */}
                {/* <div className="col float-end">
                  <div className=" float-end me-1 ">
                    {numberOfNotificationToShow?.length <= 6
                      ? notifications?.length
                      : numberOfNotificationToShow}{" "}
                    of {notifications?.length}
                  </div>
                </div> */}
              </div>

              {notifications && notifications?.length > 0 ? (
                notifications
                  ?.slice(0, numberOfNotificationToShow)
                  ?.map((noti, i) => (
                    <>
                      <Link
                        onClick={() => handleRead(noti?._id)}
                        // onClick={() => alert(route)}
                        to={
                          noti?.type === "Templete"
                            ? `/${route}/Template-view/${noti?.templete_Id}`
                            : noti?.type === "Document"
                            ? `/${route}/view-details/${noti?._id}`
                            : ""
                        }
                        className={`d-flex align-items-center text-dark p-1 my-1 rounded ${
                          noti?.isRead ? "bg-white" : "unread"
                        }`}
                        state={
                          noti?.type === "Templete"
                            ? `${noti?.templete_Id}`
                            : `${noti?.document_Id}`
                        }
                        // state={noti?.templete_Id}
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              src="/images/dashboard/pdf-icon.svg"
                              alt="dash"
                            />
                          </div>
                          <div className="right_side_bar">{noti?.title}</div>
                          {/* <p className="sidebar-time flex-end">
                            {moment(noti?.createdAt).format("DD/MM")}
                          </p> */}
                        </div>
                      </Link>
                    </>
                  ))
              ) : (
                <p className="text-center">No notifications</p>
              )}
            </ul>

            <ul className="nav nav-pills flex-column my-4 ">
              {/* <span className="right-sidebar-heading mt-3 mb-3">Activities</span> */}
              <div className="row bg-white">
                <div className="col float-start py-2">
                  <h4 className="text-align-left float-start current-day">
                    Activities
                  </h4>
                </div>
                {/* <div className="col float-end">
                  <div className=" float-end pt-2">
                    {activity?.length <= 8
                      ? activity?.length
                      : numberOfActivityToShow}
                    of {activity?.length}
                  </div>
                </div> */}
                {/* <div className="col float-end">
                  <div className=" float-end me-1 mt-4">
                    <img
                      src="/images/chatbox/More-dots.svg"
                      alt=""
                      className="me-2"
                    />
                  </div>
                </div> */}
              </div>

              {activity && activity?.length > 0 ? (
                activity
                  ?.slice(currentActivity, numberOfActivityToShow)
                  ?.map((activity) => (
                    <li className="nav-item text-dark my-1 py-1">
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            src="/images/icons/activity.png"
                            className="w_20_h_20"
                          />
                          {/* <img src="/images/sidebar/Avatar1.png" /> */}
                        </div>

                        <div className=" right_side_bar">
                          {" "}
                          {activity?.title}
                        </div>
                        <p className="sidebar-time flex-end">
                          {moment(activity?.createdAt).format("DD/MM")}
                        </p>
                      </div>
                    </li>
                  ))
              ) : (
                <p className="text-center">No activity found</p>
              )}

              {activity?.length > 8 && (
                <>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ gap: "15px" }}
                  >
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev - 8);
                        setNumberOfActivityToShow((prev) => prev - 8);
                      }}
                      disabled={currentActivity <= 0}
                      className="w-100 rounded-3"
                    >
                      <span className="fs-4">«</span>
                    </button>
                    <button
                      onClick={() => {
                        setCurrentActivity((prev) => prev + 8);
                        setNumberOfActivityToShow((prev) => prev + 8);
                      }}
                      disabled={currentActivity + 8 >= activity.length}
                      className="w-100 rounded-3"
                    >
                      <span className="fs-4">»</span>
                    </button>
                  </div>
                </>
              )}

              {/* {notifications?.length > 8 && (
                <>
                  <button
                    onClick={() => {
                      setCurrentActivity((prev) => prev + 8);
                      setNumberOfActivityToShow((prev) => prev + 8);
                      console.log(currentActivity, numberOfActivityToShow);
                    }}
                    disabled={
                      currentActivity + numberOfActivityToShow >=
                      notifications.length
                    }
                    className="w-100"
                  >
                    Show More
                  </button>
                  <button
                    onClick={() => {
                      setCurrentActivity((prev) => prev - 8);
                      setNumberOfActivityToShow((prev) => prev - 8);
                      console.log(currentActivity, numberOfActivityToShow);
                    }}
                    disabled={
                      notifications?.length <= 8 || currentActivity <= 8
                    }
                    className="w-100"
                  >
                    Previous
                  </button>
                </>
              )} */}
            </ul>

            <SideCalendar />
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebarEmply;
