import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { SuperAdminProfile,SuperAdminCompanyNotification } from "../../ApiServices/superadminHttpServices/superadminHttpServices";

const MiddleHeader = () => {
  const [profileData, setProfileData] = useState();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [activity, setActivity] = useState([])
  const [data, setdata] = useState([]);

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("token-main-admin");
    if (!token) {
      navigate("/main/login");
    }
  }, []);

  const handleLogout = async () => {
    Swal.fire({
      toast: true,
      icon: "success",
      position: "top-end",
      title: "Logout successfully",
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    });

    localStorage.removeItem("token-main-admin");
    navigate("/main/login");
  };

  const getProfileData = async () => {
    let emp_id = localStorage.getItem("super-admin-id");
    const { data } = await SuperAdminProfile(emp_id);
    console.log(data?.results?.admin?.image);
    if (!data?.error) {
      setProfileData(data?.results?.admin);
    }
  };
  const adminListing = async () => {
    let { data } = await SuperAdminCompanyNotification();
    console.log(data);
    if (!data?.error) {
      setdata(data?.results?.list);
    }
  };

  useEffect(() => {
    adminListing()
  }, [])
  
  const closeModal = () => {
    setModal(false);
  }

  return (
    <>
      <div className="col d-flex align-items-center  justify-content-end mt-2">
        <div className="profile_part mt-2">
          <img src="/images/dashboard/round-notifications.png" alt=""            onClick={() => setModal(!modal)}
/>
          {modal && (
            <div className="position-absolute top-0 end-0 mt-5">
<div className="notification_modal position-relative">
      <div className="d-flex align-items-center bg-white z-1 justify-content-between border-bottom p-2 px-3 position-sticky top-0 end-0 start-0">
        <p className="title mb-0 py-2">All Notifications</p>
        <div>
          <img
            src="/images/icons/close.png"
            className="w_20_h_20 cursor_pointer"
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>

      <div className="px-2">
        {data &&
          data?.length > 0 &&
          data?.map((noti, i) => (
            <>
              <div className="nav-item text-dark py-2" key={noti?._id}>
                <div className="d-flex align-items-center">
                  <div>
                    <img src="/images/dashboard/pdf-icon.svg" />
                    {/* <img src="/images/sidebar/Featured Icon.png" /> */}
                  </div>

                  <div className="ms-2">{noti?.title}</div>
                  <p className="sidebar-time flex-end">
                    {moment(noti?.createdAt).calendar()}
                  </p>
                </div>
              </div>
            </>
          ))}
      </div>
    </div>            </div>
          )}

          <div className="header_profile">
            <div className="dropdown">
              <button
                className="rounded-circle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="propic">
                  <img
                  style={{borderRadius:'50%'}}
                    src={
                      (profileData?.image && profileData.image) ||
                      "/images/dashboard/profile.png"
                    }
                    alt
                  />
                </div>
                <span>{profileData?.name}</span>
                <img src="/images/dashboard/arrow.png" alt />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <Link to="/main/Profile" className="dropdown-item">
                    <img src="/images/dashboard/profilee.png" alt /> Profile
                  </Link>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => handleLogout()}>
                    <img src="/images/dashboard/logot.png" alt />
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleHeader;
