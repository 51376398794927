import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AdminNotifications,
  ReadNotifications,
} from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import {
  markNotificationAsRead,
  setNotificationList,
} from "../app/slice/notificationSlice";

const NotificatioModal = ({ closeModal, navRole }) => {
  const notification = useSelector(
    (state) => state.notification?.notificationList
  );
  const [route, setRoute] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname.startsWith("/Admin")) {
      setRoute("Admin");
    } else if (location?.pathname.startsWith("/Signatory")) {
      setRoute("Signatory");
    } else if (location?.pathname.startsWith("/Employee")) {
      setRoute("Employee");
    }
  }, [location]);

  const getNotifications = async () => {
    console.log("called");
    let id = await localStorage.getItem("myot_admin_id");
    const { data } = await AdminNotifications(id);
    if (data && !data?.error) {
      let val = data?.results?.list;
      let filterData = await val.filter((item) => item.role === route);
      console.log(filterData);
      dispatch(setNotificationList(filterData));
    }
  };
  const handleRead = async (id, isRead) => {
    if (isRead) return;
    try {
      let { data } = await ReadNotifications(id);
      if (data && !data?.error) {
        console.log(data);
        getNotifications();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="notification_modal position-relative">
      <div className="d-flex align-items-center bg-white z-1 justify-content-between border-bottom p-2 px-3 position-sticky top-0 end-0 start-0">
        <p className="title mb-0 py-2">All Notifications</p>
        <div>
          <img
            src="/images/icons/close.png"
            className="w_20_h_20 cursor_pointer"
            alt=""
            onClick={closeModal}
          />
        </div>
      </div>

      <div className="">
        {notification && notification?.length > 0 ? (
          notification?.map((noti, i) => (
            <>
              <div className="nav-item text-dark my-1" key={noti?._id}>
                <Link
                  onClick={() => {
                    handleRead(noti?._id, noti?.isRead);
                    closeModal();
                  }}
                  to={
                    noti?.type === "Templete"
                      ? `/${route}/Template/View-template`
                      : noti?.type === "Document"
                      ? `/${route}/Requests/View-Document`
                      : ""
                  }
                  state={
                    noti?.type === "Templete"
                      ? `${noti?.templete_Id}`
                      : `${noti?.document_Id}`
                  }
                  className={`d-flex align-items-center text-dark p-2 my-0 ${
                    noti?.isRead ? "bg-light" : "unread"
                  }`}
                >
                  <div>
                    <img src="/images/dashboard/pdf-icon.svg" />
                    {/* <img src="/images/sidebar/Featured Icon.png" /> */}
                  </div>

                  <div className="ms-2">{noti?.title}</div>
                  <p className="sidebar-time flex-end">
                    {moment(noti?.createdAt).calendar()}
                  </p>
                </Link>
              </div>
            </>
          ))
        ) : (
          <p className="text-center">No notifications</p>
        )}
      </div>
    </div>
  );
};

export default NotificatioModal;
