import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import Swal from "sweetalert2";
import Select from "react-select";

import {
  AddTemplates,
} from "../../ApiServices/departmentHttpService/departmentHttpService";
import { FetchDepartmentList } from "../../ApiServices/dashboardHttpService/dashboardHttpServices";
import { MultiSelect } from "react-multi-select-component";
// import {approverOptions} from '../app/'

const TemplateModal = ({ imp, files, content, fields, draftId }) => {
  const [loader, setLoader] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [approId, setapproId] = useState("");
  const [signId, setsignId] = useState("");
  const [selectedMember, setSelectedMember] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [departments, setdepartments] = useState([]);
  const [depOptions, setdepOptions] = useState([]);
  const [alrt, setalrt] = useState(false);
  const [TempID, setTempID] = useState("");
  const [selected, setSelected] = useState([]);
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  const location = useLocation();
  const approverOptions = useSelector(
    (state) => state.template.approverOptions
  );
  const signOptions = useSelector((state) => state.template.signOptions);
  const adminOptions = useSelector((state) => state.template.adminOptions);

  const id = localStorage.getItem("user_id");

  useEffect(() => {
    const createUsersOptions = async () => {
      if (approverOptions) {
        const options = approverOptions.map((item, index) => ({
          value: item._id,
          label: item.name,
        }));
        // Set the state variable to the index of the desired item
        options.sort((a, b) => a.label.localeCompare(b.label));
        setUserOptions(options);
      }
    };

    createUsersOptions();
  }, []);

  useEffect(() => {
    const createUsersOption = async () => {
      if (signOptions) {
        const options = signOptions.map((item, index) => ({
          value: item._id,
          label: item.name,
        }));
        setsignId(options.findIndex((option) => option.value === imp));
        options.sort((a, b) => a.label.localeCompare(b.label));
        setUserOption(options);
      }
    };
    createUsersOption();
  }, []);
  
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        let idd = localStorage.getItem("user_id");
        const response = await FetchDepartmentList(idd);
        setdepartments(response?.data?.results?.department);
        console.log(response);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    const departmentOPtion = async () => {
      if (departments) {
        const options = departments.map((item) => ({
          value: item._id,
          label: item.departmentName,
        }));
        options.sort((a, b) => a.label.localeCompare(b.label));
        setdepOptions(options);
      }
    };
    departmentOPtion();
  }, [departments]);

  const depManagerOptions = useSelector(
    (state) => state.template.depManagerOptions
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({ mode: "onChange" });


  const onSubmit = async (info) => {
    setLoader(true);
    let formData = new FormData();
    console.log(info);
    let usersArr = [];
    let userArr = [];
    let managerArr = [];
    let signIdd = [];
    let appIdd = [];
    if (selected && Array.isArray(selected)) {
      selected.forEach((item, index) => {
        managerArr.push(item?.value);
      });
    } else if (selected) {
      managerArr.push(selected);
    }

    await selectedMembers?.map((item, index) => {
      usersArr.push(item?.value);
    });
    await selectedMember?.map((items, index) => {
      userArr.push(items?.value);
    });
    formData.append("templeteName", capitalizeFirstLetter(info?.templeteName));
    formData.append("approver", JSON.stringify(usersArr));
    formData.append("signatory", JSON.stringify(userArr));
    formData.append("department", JSON.stringify(managerArr));
    formData.append("creator_Id", id);
    formData.append("tempApprov", JSON.stringify([usersArr[0]]));
    formData.append("tempSign", JSON.stringify([userArr[0]]));
    formData.append("templete", files);
    formData.append("content", content);
    formData.append("fileName", JSON.stringify(fields));

    try {
      const { data } = await AddTemplates(formData);
      if (data && !data?.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Template Added",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        document.getElementById("closeModal").click();
        navigate("/Department/Template");
      }
    } catch (error) {
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleSelectChange = async (selectedMembers) => {
    const userId = localStorage.getItem("user_id");

    const matchedMembers = selectedMembers.some(
      (member) => member.value === userId
    );
    setSelectedMembers(selectedMembers);
  };
  const handleCancel = () => {
    // Navigate to the desired route when cancel button is clicked
    navigate(-1);
  };

  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-department">
          <div class="modal-content border-0">
            <div class="d-flex modal-header border-bottom">
              <p class="fw-bold" id="exampleModalLabel">
                {/* Conditional rendering based on location */}
                {location.pathname.match("/Department/Template/Create-Template")
                  ? "Add Template"
                  : location.pathname.match(
                      "/Department/Template/Edit-Template"
                    )
                  ? "Edit Template"
                  : // Default content if location doesn't match either condition
                    "Default Content"}
              </p>
              <button
                type="reset"
                class="btn-close"
                id="closeModal"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row p-3">
                <div className="col-12 mb-3">
                  <input
                    type="text"
                    placeholder="Template Title"
                    name="templeteName"
                    // defaultValue={userDetails?.employTitle}
                    className={classNames("col-12 modal-input td-text  p-2", {
                      "is-invalid": errors.templeteName,
                    })}
                    {...register("templeteName", {
                      required: "* Template title is required",
                      pattern: {
                        value: /^(?!\s)[^\d]*(?:\s[^\d]+)*$/,
                        message:
                          "Spaces at the start & numbers are not allowed",
                      },
                    })}
                  />
                  {errors.templeteName && (
                    <div className="invalid-feedback">
                      {errors.templeteName.message}
                    </div>
                  )}
                </div>
                {/* <div className="col-md-6 mb-3">
                  <input
                    autoComplete="false"
                    className="form-control cursor_pointer"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    id="profile_img"
                    name="profile_img"
                    type="file"
                    required
                    accept="application/pdf"
                    defaultValue=""
                    onChange={(e) => onFileSelection(e, "profile_img")}
                  />
                </div> */}
                <div className="col-12 mb-3 ">
                  <p className="bg-primary-subtle th-text ps-4 p-2">Assign</p>
                </div>
                <div className="col-12  border-light mb-3 ">
                  <div className="row">
                    <p className="col-4 td-text p-2">
                      {/* <input
                        className=" form-check-input checkbox-table ms-4 me-4"
                        type="checkbox"
                        value=""
                      /> */}
                      Approver
                    </p>
                    <Select
                      placeholder="Select Approvers"
                      options={userOptions}
                      isMulti
                      // {...register("approver", {
                      //   required: "* Please select at least one Approver",
                      //   validate: {
                      //     nonEmpty: (value) => value && value.length > 0, // Custom validation to check if at least one Approver is selected
                      //   },
                      // })}
                      className={classNames(
                        "form-control col modal-input th-text p-2 me-3",
                        {
                          "is-invalid": errors.approver,
                        }
                      )}
                      classNamePrefix="w-100"
                      value={selectedMembers}
                      onChange={handleSelectChange}
                    />
                    {/* {errors.approver && (
                      <div className="invalid-feedback text-center">
                        {errors.approver.message}
                      </div>
                    )} */}
                  </div>
                </div>
                <div className="col-12  border-light mb-3 ">
                  <div className="row">
                    <p className="col-4 td-text p-2">
                      {/* <input
                        className=" form-check-input checkbox-table ms-4 me-4"
                        type="checkbox"
                        value=""
                      /> */}
                      Signatory
                    </p>
                    <Select
                      placeholder="Select Signatory"
                      options={userOption}
                      isMulti
                      className={classNames(
                        "form-control col modal-input th-text p-2 me-3",
                        {
                          "is-invalid": errors.signatory,
                        }
                      )}
                      classNamePrefix="w-100"
                      value={selectedMember}
                      onChange={(selectedMember) =>
                        setSelectedMember(selectedMember)
                      }
                    />
                    {errors.signatory && (
                      <div className="invalid-feedback text-center">
                        {errors.signatory.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12  mb-3 ">
                  <div className="row">
                    <p className="col-4 td-text p-2">
                      {/* <input
                        className=" form-check-input checkbox-table ms-4 me-4"
                        type="checkbox"
                        value=""
                      /> */}
                      Departments
                    </p>
                    <MultiSelect
                      options={depOptions}
                      className={classNames(
                        "form-control col modal-input th-text p-2 me-3",
                        {
                          "is-invalid": errors.manager,
                        }
                      )}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                    {/* <select
                      name="manager"
                      className={classNames(
                        "form-control col modal-input th-text p-2 me-3",
                        {
                          "is-invalid": errors.manager,
                        }
                      )}
                      {...register("manager", {
                        required: "* Please select a Manager",
                      })}
                    >
                      <option value="" className="w-100">
                        Select Manager
                      </option>
                      {depManagerOptions &&
                        depManagerOptions?.map((options) => (
                          <option
                            className="w-100"
                            key={options?._id}
                            value={options?._id}
                          >
                            {options?.name}
                          </option>
                        ))}
                    </select> */}
                    {errors.manager && (
                      <div className="invalid-feedback text-center">
                        {errors.manager.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-0">
                <Button
                  style={{ width: "150px" }}
                  appearance="danger"
                  className="btn mb-3 me-2 rounded-2 cancel_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Button>

                <Button
                  style={{ width: "150px" }}
                  loading={loader}
                  appearance="primary"
                  className="btn mb-3 me-2 rounded-2"
                  type="submit"
                  disabled={!isValid}
                >
                  Add New
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateModal;
