import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  document: null,
  signatoryDocs: null,
};

const documentSlice = createSlice({
  name: "employeeDocument",
  initialState,
  reducers: {
    setEmployeeDocument(state, action) {
      state.document = action.payload;
    },
    setSignatoryDocs(state, action) {
      state.signatoryDocs = action.payload;
    },
  },
});

export default documentSlice.reducer;
export const { setEmployeeDocument, setSignatoryDocs } = documentSlice.actions;
