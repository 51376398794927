import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  approverOptions: [],
  signOptions: [],
  adminOptions: [],
  depManagerOptions: [],
};

const createTemplateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setApprovers(state, action) {
      state.approverOptions = action.payload;
    },
    setSignatories(state, action) {
      state.signOptions = action.payload;
    },
    setAdmins(state, action) {
      state.adminOptions = action.payload;
    },
    setDeptManagers(state, action) {
      state.depManagerOptions = action.payload;
    },
  },
});

const { setApprovers, setSignatories, setAdmins, setDeptManagers } = createTemplateSlice.actions;

export default createTemplateSlice.reducer;

export { setApprovers, setSignatories, setAdmins, setDeptManagers };
