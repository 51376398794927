import React from "react";
import { Link } from "react-router-dom";

// Define pricing plans data
const pricingPlans = [
  {
    type: "Basic",
    price: 5,
    duration: "/mo",
    description: "Total $5/ month Billed annually",
    features: [
      "Core document creation and management",
      "Basic approval workflows",
      "Pre-designed templates",
      "Email support",
      "Limited integrations",
    ],
    buttonText: "Get Started with Basic",
    active: false, // Not active
    navigateTo: "/Admin/Signup",
    size: 25,
  },
  {
    type: "Pro",
    price: 100,
    duration: "/mo",
    description: "Consider pricing between $10 to $20/user/month",
    features: [
      "All Basic Plan features",
      "Advanced approval workflows",
      "API access for custom integrations",
      "Priority email and chat support",
      "Customizable templates",
      "Basic analytics and reporting",
    ],
    buttonText: "Upgrade to Pro",
    active: true, // Active by default
    navigateTo: "/Admin/Signup",
    size: 20,
  },
  {
    type: "Custom",
    price: "Contact Us",
    duration: "",
    description: "",
    features: [
      "All Pro Plan features",
      "Dedicated account manager",
      "Custom integrations and workflows",
      "On-premises deployment option",
      "Advanced analytics and reporting",
      "SLA with guaranteed uptime",
      "24/7 support",
    ],
    buttonText: "Contact Us for a Custom Plan",
    active: false, // Not active
    navigateTo: "/contact",
    size: "Custom",
  },
];

const Pricing = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="heading_comman text-center">
            <h2>Pricing Plans</h2>
            <p>
              Flexible pricing options designed to fit your business needs,{" "}
              <br /> whether you're just getting started or scaling your
              operations.
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            {pricingPlans.map((plan, index) => (
              <div
                key={index}
                className="col-md-4 px-lg-4 px-md-2 px-3 mt-4 mt-md-0"
              >
                <div className={`pricing_box ${plan.active ? "active" : ""}`}>
                  {plan.type === "Pro" && (
                    <div className="priceenew_tag">Simple Pack</div>
                  )}
                  <div className="pricing_top">
                    <div className="price_typ">{plan.type}</div>
                    <div className="pricee">
                      {plan.price} <span>{plan.duration}</span>
                    </div>
                    <div className="price_sub">{plan.description}</div>
                  </div>
                  <div className="include_benefit">
                    <h2>Features</h2>
                    <ul className="list-unstyled p-0 mb-0">
                      {plan.features.map((feature, idx) => (
                        <li key={idx}>
                          <p>{feature}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link
                    to={plan?.navigateTo}
                    className="price_btn"
                    state={plan}
                  >
                    {plan.buttonText}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
