import React, { useEffect, useState } from "react";
import {
  AnnouncementLists,
  CreateAnnouncement,
} from "../../../ApiServices/dashboardHttpService/dashboardHttpServices";
import moment from "moment";
import { Button } from "rsuite";
import Swal from "sweetalert2";
import { capitalizeFirstLetter } from "../../../utils/capitalize";

const CommonAnnoucement = ({ isAdmin = false }) => {
  const [documents, setDocuments] = useState([]);
  const [announcementType, setAnnouncementType] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getAnnouncements();
  }, []);

  const getAnnouncements = async () => {
    let id = localStorage.getItem("myot_admin_id");
    let { data } = await AnnouncementLists(id);
    // console.log(data);
    if (!data?.error) {
      let values = data?.results?.announcementList;
      values?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
      setDocuments(values);
    }
  };

  const handleImgChange = (e, key) => {
    setFiles({ ...files, [key]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let id = localStorage.getItem("myot_admin_id");
    let formData = new FormData();
    formData.append("categoryName", announcementType);
    formData.append("document", files?.doc_img);
    formData.append("text", capitalizeFirstLetter(description));
    formData.append("creator_Id", id);

    try {
      let { data } = await CreateAnnouncement(formData);
      // console.log(data);
      if (data && !data?.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Announcement created",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        setAnnouncementType("");
        setDescription("");
        setFiles([]);
        document.getElementById("resetForm").click();
        document.getElementById("modalClose").click();
        getAnnouncements();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <p className="table-name mb-2">Broadcast Announcement</p>
        {isAdmin && (
          <div className="d-flex justify-content-center th-text">
            <div
              className="d-flex whitespace-nowrap"
              type="button"
              data-bs-toggle="modal"
              data-bs-target={isAdmin ? "#exampleModal" : ""}
            >
              <img src="/images/tasks/Add.svg" alt="" className="pb-3" />
              <p className="pt-0 text-nowrap fw-bold">Add Announcement</p>
            </div>
          </div>
        )}
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-department">
          <div class="modal-content border-0">
            <form onSubmit={handleSubmit}>
              <div class="d-flex modal-header border-bottom">
                <p class="mb-0" id="exampleModalLabel">
                  Create New Announcement
                </p>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="modalClose"
                ></button>
              </div>

              <div className="row p-3">
                <div className="col-12 mb-3 d-flex">
                  <div className="col-6 pe-3">
                    <label htmlFor="announcementType" className="mb-3">
                      Announcement Category
                    </label>
                    <input
                      type="text"
                      placeholder="Announcement Category"
                      className="col-12 modal-input td-text  p-2"
                      name="announcementType"
                      id="announcementType"
                      value={announcementType}
                      onChange={(e) => setAnnouncementType(e.target.value)}
                    />
                  </div>
                  <div className="col-6 mb-3 ">
                    <label htmlFor="doc_file" className="mb-3">
                      Document Upload
                    </label>
                    <input
                      type="file"
                      placeholder="File Format: JPG, JPEG, PNG or PDF Size: Upto 500KB"
                      className="col-12 modal-input td-text p-2 display-none"
                      name="doc_file"
                      id="doc_file"
                      onChange={(e) => handleImgChange(e, "doc_img")}
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                </div>
                <div className="col-12 mb-3 ">
                  <label htmlFor="description" className="mb-3">
                    Description
                  </label>
                  <textarea
                    type="text"
                    placeholder=""
                    className="col-12 modal-input td-text p-2 text-area"
                    name="description"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-end mb-3 me-3">
                <Button
                  style={{ width: "150px" }}
                  loading={loader}
                  appearance="primary"
                  className="btn mb-3 me-2 rounded-2"
                  type="submit"
                  disabled={!announcementType || !description}
                >
                  Add New
                </Button>
                <Button
                  style={{ width: "100px" }}
                  type="reset"
                  className="btn mb-3 mx-2 rounded-2 cancel_btn border-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </Button>
                <button type="reset" id="resetForm" className="d-none">
                  reset
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- Modal End--> */}

      <div className="container rounded mb-3">
        {documents && documents?.length > 0 ? (
          documents?.map((document) => (
            <>
              <div className="row my-4 bg-body-tertiary">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="templates-leave mt-3 ms-2 mb-0 ">
                    {`Announcement > ${document?.categoryName}`}
                  </p>
                  <p className="announcement-time">
                    {moment(document?.createdAt).calendar()}
                  </p>
                </div>
                <div className="col-12">
                  <div className="rounded bg-white d-flex m-3 p-2 algin-items-center justify-content-between">
                    <div className="ps-2 pe-3 w-100">
                      <div className="d-flex">
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                          }}
                          src={
                            document?.document
                              ? document?.document
                              : "/images/dashboard/user (2) 1.svg"
                          }
                          alt=""
                          className="me-2"
                        />
                        <p className="anouncement-text ms-1">
                          {document?.text}
                        </p>
                      </div>
                      <div className="d-flex ms-4 mt-1">
                        <img
                          src="/images/dashboard/bookmark 1.svg"
                          alt=""
                          className="m-1 "
                        />
                        <p className="new-feedback">New feedback added</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col rounded bg-white d-flex m-3 p-2">
                        <div className="ps-2 pe-3">
                          <div className="d-flex">
                            <img
                              src="/images/dashboard/user (2) 1.svg"
                              alt=""
                              className="me-2"
                            />
                            <p className="anouncement-text">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry.
                            </p>
                          </div>
                          <div className="d-flex ms-4 mt-1">
                            <img
                              src="/images/dashboard/bookmark 1.svg"
                              alt=""
                              className="m-1 "
                            />
                            <p className="new-feedback">New feedback added</p>
                          </div>
                        </div>
                        <p className="announcement-time">
                          Yesterday at 4:17 Pm
                        </p>
                      </div> */}
                </div>
              </div>
            </>
          ))
        ) : (
          <>
            <h6 className="text-center my-5">Yet to create a announcement</h6>
          </>
        )}
      </div>
    </>
  );
};

export default CommonAnnoucement;
