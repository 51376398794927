import React, { useEffect, useState } from "react";
import RightSidebar from "../../RightSidebar";
import Footer from "../../Common/Footer/Footer";
import Sidebar from "../../Sidebar";
import TopbarLayout from "../../Topbar/TopbarLayout";
import { Link } from "react-router-dom";
import { Checkbox } from "rsuite";
import { MDBDataTable } from "mdbreact";

const Transaction = () => {
  const [showClearButton, setShowClearButton] = useState(false);

  const [transM, setTransM] = useState({
    columns: [
      {
        label: "ID",
        field: "id",
        width: 50,
        selected: false,
      },
      {
        label: "Transaction Name",
        field: "name",
        width: 50,
        selected: false,
      },
      {
        label: "Description",
        field: "description",
        width: 50,
        selected: false,
      },
      {
        label: "Amount",
        field: "description",
        width: 50,
        selected: false,
      },
      {
        label: "Date",
        field: "description",
        width: 50,
        selected: false,
      },
      {
        label: "Actions",
        field: "actions",
        width: 50,
        selected: false,
      },
    ],
    rows: [],
    hiddenColumns: [],
    selectedColumns: [],
  });

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = async (key) => {
    // const { data } = await transMList(userId, { search: key });
    let data;
    const newRows = [];
    if (!data?.error) {
      // setListItems(data?.results?.transM);
      let values = data?.results?.transM;
      values?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt));
      values?.map((data) => {
        let returnData = {};
        returnData.name = data?.transMName;
        returnData.id = data?.departmenID;
        // returnData.description = (
        //   <>
        //     <span
        //       style={{ maxWidth: "150px" }}
        //       className="d-inline-block text-truncate"
        //     >
        //       {data?.description}
        //     </span>
        //   </>
        // );
        returnData.description = (
          <span
            style={{ maxWidth: "200px" }}
            className="d-inline-block text-truncate"
          >
            {data?.description}
          </span>
        );
        returnData.actions = (
          <>
            <div class="">
              <a
                type=""
                className="mx-auto"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/images/sidebar/ThreeDots.svg"
                  className="w-auto cursor_pointer"
                />
              </a>
              <ul class="dropdown-menu border-0 shadow p-3 rounded">
                <li>
                  <Link
                    // to={`/Admin/transM/View/${data?._id}`}
                    to="/Admin/transM/View"
                    state={data?._id}
                    class="dropdown-item"
                  >
                    <img
                      src="/images/users/AddressBook.svg"
                      alt=""
                      className="me-2"
                    />
                    View transM
                  </Link>
                </li>
                <li
                //   onClick={() => {
                //     setDeptId(data?._id);
                //     transMDetails(data?._id);
                //   }}
                >
                  <a
                    class="dropdown-item"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                  >
                    <img
                      src="/images/users/PencilLine.svg"
                      alt=""
                      className="me-2"
                    />
                    Edit transM
                  </a>
                </li>
                {/* <li>
                  <a class="dropdown-item border-bottom" href="#">
                    <img
                      src="/images/users/TextAlignLeft.svg"
                      alt=""
                      className="me-2"
                    />
                    Wrap Column
                  </a>
                </li> */}
                {/* <li
                  className="cursor_pointer"
                  onClick={() => transM(data?._id)}
                >
                  <a class="dropdown-item text-danger">
                    <img
                      src="/images/users/Trash.svg"
                      alt=""
                      className="me-2"
                    />
                    Delete transM
                  </a>
                </li> */}
              </ul>
            </div>
          </>
        );
        newRows.push(returnData);
      });
      setTransM({ ...transM, rows: newRows });
    }
  };

  const handleCheckboxChange = (field) => {
    let updatedSelectedColumns = [...transM?.selectedColumns];
    const index = updatedSelectedColumns.indexOf(field);
    if (index > -1) {
      updatedSelectedColumns.splice(index, 1);
    } else {
      updatedSelectedColumns.push(field);
    }
    setTransM({ ...transM, selectedColumns: updatedSelectedColumns });
    if (updatedSelectedColumns.length === 0) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  };

  const hideSelectedColumns = () => {
    const updatedHiddenColumns = [
      ...transM?.hiddenColumns,
      ...transM?.selectedColumns,
    ];
    setTransM({
      ...transM,
      hiddenColumns: updatedHiddenColumns,
      selectedColumns: [],
    });
    setShowClearButton(true);
  };

  const handleSort = (field) => {
    const data = transM?.rows.slice();
    let sortType = "asc";

    if (field === transM?.sortColumnName) {
      sortType = transM?.sortType === "asc" ? "desc" : "asc";
    }

    data.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (field === "description") {
        valueA = a?.description?.props?.children?.toLowerCase();
        valueB = b?.description?.props?.children?.toLowerCase();
      }
      // Compare values
      if (valueA < valueB) {
        return sortType === "asc" ? -1 : 1;
      } else if (valueA > valueB) {
        return sortType === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });

    setTransM({
      ...transM,
      rows: data,
      sortColumnName: field,
      sortType: sortType,
    });
  };

  const columnsWithCheckboxes = transM?.columns.map((column) => ({
    ...column,
    label: (
      <div key={column.field} className="t_head_box">
        <Checkbox
          checked={transM?.selectedColumns.includes(column.field)}
          onChange={() => handleCheckboxChange(column.field)}
          className="me-1 mt-1"
          defaultChecked
        ></Checkbox>
        <span
          className="mdb_table_header"
          onClick={() => handleSort(column.field)}
        >
          {column.label}
          {column.field !== "comments" && column.field !== "actions" && (
            <span className="ms-3">
              <img src="/images/icons/sort.png" alt="" />
            </span>
          )}
        </span>
      </div>
    ),
  }));

  const visibleColumns = columnsWithCheckboxes.filter(
    (column) => !transM?.hiddenColumns.includes(column.field)
  );

  const showAllColumns = () => {
    setTransM({ ...transM, hiddenColumns: [], selectedColumns: [] });
    setShowClearButton(false);
  };

  const hiddenColumnsCount = () => {
    return transM.hiddenColumns.length;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 sidebar">
          <Sidebar />
        </div>
        <div className="col-7 middle-content">
          <div className="container-fluid border-bottom sticky-top bg-white mb-4">
            <nav className="row header bg-white  ">
              <TopbarLayout />
            </nav>
          </div>

          <div className="position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <p className="table-name mb-2">Transactions</p>
            </div>
            <div className=" col-12 d-flex align-items-center table-searchbar">
              <div className="d-flex ">
                <div className="d-flex ms-2 align-items-center justify-content-around table-searchbar-txt">
                  <p className="m-0 text-nowrap">
                    <span>Selected : </span>
                    {transM?.selectedColumns && transM?.selectedColumns.length}
                  </p>
                  <div className="text-muted ms-3">
                    Hidden: {hiddenColumnsCount()}
                  </div>
                  {showClearButton ? (
                    <p
                      className="hide-selected ms-2 m-0 text-nowrap cursor_pointer "
                      onClick={showAllColumns}
                    >
                      Clear Selection
                    </p>
                  ) : (
                    <button
                      className="hide-selected m-0 ms-2 text-nowrap cursor_pointer "
                      onClick={hideSelectedColumns}
                      disabled={transM?.selectedColumns.length === 0}
                    >
                      Hide Selected
                    </button>
                  )}
                </div>
                <div class="search_icon">
                  <img
                    width={20}
                    src={require("../../../assets/logo/search.png")}
                  ></img>
                </div>
              </div>
              <form className="d-flex me-2" role="search"></form>
            </div>
            <div className="col-12 mdb_table mt-3 ">
              <div className="table-responsive">
                <MDBDataTable
                  bordered
                  displayEntries={true}
                  entries={10}
                  className="text-nowrap"
                  hover
                  data={{ ...transM, columns: visibleColumns }}
                  noBottomColumns
                  paginationLabel={"«»"}
                  sortable={false}
                />
              </div>
            </div>
          </div>

          <div className="footer">
            <Footer />
          </div>
        </div>
        <div className="col">
          <RightSidebar />
        </div>
      </div>
    </div>
  );
};

export default Transaction;
