import React from "react";

const Footer = () => {
  return (
    <>
      <div>© 2023 MYOT</div>
      <div className="d-flex ">
        <p className="ms-3">About</p>
        <p className="ms-3">Support</p>
        <p className="ms-3">Contact Us</p>
      </div>
    </>
  );
};

export default Footer;
