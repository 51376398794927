import React, { useEffect, useState } from "react";
import "../../assets/styles/accordion.css";
import { GetFaqs } from "../../ApiServices/dashboardHttpService/dashboardHttpServices";

const FaqComp = () => {
  const [faqContent, setFaqContent] = useState([]);

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = async () => {
    try {
      let { data } = await GetFaqs();
      if (data && !data?.error) {
        setFaqContent(data?.results?.faqs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="shadow">
      <h4 className="py-4 text-center">Frequently asked questions</h4>
      <div className="accordion-wrapper">
        {faqContent &&
          faqContent?.map((item, index) => (
            <div className="accordion border rounded">
              <input type="radio" name="radio-a" id={`faq-${index}`} />
              <label className="accordion-label" htmlFor={`faq-${index}`}>
                {item?.title}
              </label>
              <div className="accordion-content">
                <p>{item?.description}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FaqComp;
