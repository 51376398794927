import React, { useState } from "react";
import LandingNavbar from "./LandingNavbar";
import LandingFooter from "./LandingFooter";
import "../../assets/styles/success.css";

const RegistrationSuccess = () => {
  return (
    <>
      <LandingNavbar />

      <section className="banner_sections">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 ">
              <div className="message-box _success bg-white rounded">
                <i className="fa fa-check-circle" aria-hidden="true" />
                <h2>
                  <span>Thank you</span> <br />
                  <span>Your Registration Completed</span>
                </h2>
                <p className="mt-3">
                  Myot team will be revert back to you <br />
                  with payment link
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LandingFooter />
    </>
  );
};

export default RegistrationSuccess;
