import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationList: [],
  activityList: [],
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },
    setActivityList(state, action) {
      state.activityList = action.payload;
    },
  },
});

export default notificationSlice.reducer;
export const { setNotificationList, setActivityList } = notificationSlice.actions;
