import React from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../Components/app/slice/userSlice";
import { Navigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const PROTECTED_ROUTES = {
  admin: "/Admin",
  signatory: "/Signatory",
  approver: "/Approver",
  department: "/Department",
};

const ROLES = {
  admin: "Admin",
  signatory: "Signatory",
  approver: "Approver",
  department: "Department Manager",
};

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const userData = useSelector(selectUserData);

  const token = localStorage.getItem("token-company");
  const id = localStorage.getItem("myot_admin_id" || "user_id");

  const hasAccess = (role, path) =>
    userData?.employRole.includes(role) && location.pathname.startsWith(path);

  const showWarningAndRedirect = (title, text) => {
    Swal.fire({
      icon: "warning",
      title,
      text,
      showConfirmButton: false,
      timer: 2500,
    });
    return <Navigate to="/login" state={{ from: location }} replace />;
  };

  if (!token && !id) {
    return showWarningAndRedirect("Login Please", "You are not logged in!");
  }

  const userHasAccess = Object.entries(PROTECTED_ROUTES).some(([role, path]) =>
    hasAccess(ROLES[role], path)
  );

  if (!userHasAccess) {
    return showWarningAndRedirect(
      "Access denied",
      "You are not authorized to access this page"
    );
  }

  return children;
};

export default ProtectedRoutes;